// UserCustMana - 사용자관리 - 계정 관리
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { LoadPanel } from 'devextreme-react';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, NumbCol, StatCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { ContentHeader } from '../../../../script/components/header/Header';
import { fwUtil } from '../../../../script/util';

const userTypeCell = (cellInfo) => {
    const value = cellInfo.value;
    let displayText = '-';
    let color = '#9E9E9E';

    if (value === "P") {
        displayText = '개인';
        color = '#2196F3';
    } else if (value === "C") {
        displayText = '법인';
        color = '#3F51B5';
    }

    return (
        <div className={'fw-user-level-column'}>
            <span className={`fw-chrg-state-text`} style={{ color: color }}>● {displayText}</span>
        </div>
    );
};

const userAgreCell = (cellInfo) => {
    const value = cellInfo.value;
    let displayText = '-';
    let color = '#9E9E9E';

    if (value === "Y") {
        displayText = '동의';
        color = '#4CAF50';
    } else if (value === "N") {
        displayText = '비동의';
        color = '#FF5722';
    }

    return (
        <div className={'fw-user-level-column'}>
            <span className={`fw-chrg-state-text`} style={{ color: color }}>● {displayText}</span>
        </div>
    );
};

const userBillCell = (cellInfo) => {
    const value = cellInfo.value;
    let displayText = '-';
    let color = '#9E9E9E';

    if (value === "Y") {
        displayText = '발행';
        color = '#4CAF50';
    } else if (value === "N") {
        displayText = '미발행';
        color = '#FF5722';
    }

    return (
        <div className={'fw-user-level-column'}>
            <span className={`fw-chrg-state-text`} style={{ color: color }}>● {displayText}</span>
        </div>
    );
};

const userMembCell = (cellInfo) => {
    const value = cellInfo.value;
    let displayText = '-';
    let color = '#9E9E9E';

    if (value === "Y") {
        displayText = '가입';
        color = '#4CAF50';
    } else if (value === "N") {
        displayText = '미가입';
        color = '#FF5722';
    }

    return (
        <div className={'fw-user-level-column'}>
            <span className={`fw-chrg-state-text`} style={{ color: color }}>● {displayText}</span>
        </div>
    );
};

const userPaysCell = (cellInfo) => {
    const value = cellInfo.value;
    let displayText = '-';
    let color = '#9E9E9E';

    if (value === "A") {
        displayText = '후불제';
        color = '#4CAF50';
    } else if (value === "B") {
        displayText = '간편결제';
        color = '#FF5722';
    }

    return (
        <div className={'fw-user-level-column'}>
            <span className={`fw-chrg-state-text`} style={{ color: color }}>● {displayText}</span>
        </div>
    );
};

const userStatCell = (cellInfo) => {
    const value = cellInfo.value;
    let displayText = '-';
    let color = '#9E9E9E';

    if (value === "A") {
        displayText = '정상';
        color = '#4CAF50';
    } else if (value === "B") {
        displayText = '정지';
        color = '#F44336';
    } else if (value === "C") {
        displayText = '탈퇴';
        color = '#FF9800';
    } else if (value === "D") {
        displayText = '정상회원';
        color = '#2196F3';
    }

    return (
        <div className={'fw-user-level-column'}>
            <span className={`fw-chrg-state-text`} style={{ color: color }}>● {displayText}</span>
        </div>
    );
};

export default function UserCustMana(props) {
    const {
        acce, cate, code,
        mainTitle, mainRole,
    } = props;

    // api
    const infoMiddleApi = 'cust/regist';
    const infoSearchApi = fwUtil.apis.create(acce, cate, infoMiddleApi, 'search');
    const infoUpdateApi = fwUtil.apis.create(acce, cate, infoMiddleApi, 'update');
    // 시스템
    const [loading, setLoading] = useState(true);
    // 헤더
    const title = `${mainTitle}${mainRole ? " " + mainRole : ""}`;
    // 권한
    const { authCheck } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const gridSize = "single";
    const mainKey = 'id';
    const gridRef = useRef(null);
    const [gridData, setGridData] = useState([]);
    const gridDataUpdate = useCallback((e) => setGridData(e), []);
    // 검색 조건
    const [userName, setUserName] = useState("");
    const [compName, setCompCode] = useState("");
    const userNameChange = useCallback((e) => { setUserName(e) }, []);
    const compNameChange = useCallback((e) => { setCompCode(e) }, []);
    // 수정 팝업창
    const [infoUpdatePopupVisible, setInfoUpdatePopupVisible] = useState(false);
    const infoUpdatePopupHide = useCallback(() => { setInfoUpdatePopupVisible(false); }, []);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        authCheck(setLoading, setAuthList, code);
    }, [authCheck, setLoading, setAuthList, code]);

    // 조회
    const handleSearch = useCallback(async () => {
        const sendData = { customerName: userName, companyName: compName };
        await fwUtil.apis.search(infoSearchApi, sendData, gridDataUpdate, gridRef, null);
    }, [userName, compName, infoSearchApi, gridDataUpdate, gridRef]);

    useEffect(() => {
        handleSearch();
    }, [handleSearch]);

    // 등록/수정/삭제 버튼 클릭
    const handleActionClick = useCallback((act) => {
        const selectedRowData = fwUtil.grid.get.sData(gridRef);
        if (selectedRowData) {
            if (act === 'info-upd') {
                setInfoUpdatePopupVisible(true);
                return;
            }
        } else {
            fwUtil.aler.toast.warn('계정 선택 후 시도해주세요.');
        };

    }, [gridRef]);

    // 추가 툴바 아이템
    const toolbarItems = useMemo(() => [
        { type: 'text', locate: 'before', caption: '사용자명', value: userName, valueChange: userNameChange, search: handleSearch },
        { type: 'text', locate: 'before', caption: '회사명', value: compName, valueChange: compNameChange, search: handleSearch },
        { type: 'btns', locate: 'before', icon: 'search', text: '검색', onClick: handleSearch }
    ], [userName, userNameChange, compName, compNameChange, handleSearch]);

    if (loading) { return <LoadPanel visible={true} /> };

    return (
        <>
            <ContentHeader icon={'fw-user'} title={title} />
            <div id={'fw-page-content'}>
                <PagingGrid
                    title={'고객 현황'}
                    gridSize={gridSize}
                    mainKey={mainKey}
                    gridRef={gridRef}
                    gridDataSource={gridData}
                    exp={{ disabled: authList.auth_dnlo === 0 }}
                    toolbar={{ default: false, items: toolbarItems }}
                    selection={'single'}
                >
                    {CommCol({ dataField: "id", caption: "사용자 고유번호" })}
                    {StatCol({ dataField: "customerType", caption: "고객유형", customCell: userTypeCell })}
                    {CommCol({ dataField: "companyName", caption: "회사명" })}
                    {CommCol({ dataField: "customerName", caption: "고객명" })}
                    {CommCol({ dataField: "userId", caption: "사용자ID" })}                    
                    {CommCol({ dataField: "mobile", caption: "전화번호" })}
                    {CommCol({ dataField: "brithDay", caption: "생년월일" })}
                    {NumbCol({ dataField: "point", caption: "포인트" })}
                    {StatCol({ dataField: "notiYn1", caption: "충전시작알림", customCell: userAgreCell })}
                    {StatCol({ dataField: "notiYn2", caption: "충전종료알림", customCell: userAgreCell })}
                    {StatCol({ dataField: "notiYn3", caption: "월정산", customCell: userAgreCell })}
                    {StatCol({ dataField: "notiYn4", caption: "1:1문의", customCell: userAgreCell })}
                    {StatCol({ dataField: "notiYn5", caption: "충전예약취소알림", customCell: userAgreCell })}
                    {StatCol({ dataField: "notiYn6", caption: "예약충전10분전", customCell: userAgreCell })}
                    {StatCol({ dataField: "notiYn7", caption: "공지사항알림", customCell: userAgreCell })}
                    {StatCol({ dataField: "autoLogin", caption: "자동로그인", customCell: userAgreCell })}
                    {NumbCol({ dataField: "evModelId", caption: "EV모델ID" })}
                    {CommCol({ dataField: "social", caption: "소셜구분" })}
                    {CommCol({ dataField: "socialId", caption: "소셜ID" })}
                    {StatCol({ dataField: "pointUseYn", caption: "포인트사용여부", customCell: userAgreCell })}
                    {StatCol({ dataField: "agreeYn", caption: "개인정보동의여부", customCell: userAgreCell })}
                    {NumbCol({ dataField: "deposit", caption: "예치금" })}
                    {NumbCol({ dataField: "disPayment", caption: "미수금" })}
                    {StatCol({ dataField: "agreeYn2", caption: "추가동의", customCell: userAgreCell })}
                    {StatCol({ dataField: "marketAgreeYn", caption: "마케팅수신동의", customCell: userAgreCell })}
                    {CommCol({ dataField: "bizNum", caption: "사업자등록번호" })}
                    {CommCol({ dataField: "memCardStat", caption: "멤버쉽카드상태" })}
                    {CommCol({ dataField: "pricePlanId", caption: "요금제ID" })}
                    {CommCol({ dataField: "joinDate", caption: "가입일" })}
                    {CommCol({ dataField: "promotionId", caption: "프로모션ID" })}
                    {CommCol({ dataField: "email", caption: "이메일주소" })}
                    {CommCol({ dataField: "tel", caption: "유선전화" })}
                    {CommCol({ dataField: "bizCode", caption: "업종코드" })}
                    {CommCol({ dataField: "bizType", caption: "업태" })}
                    {StatCol({ dataField: "taxBillYn", caption: "세금계산서발행여부", customCell: userBillCell })}
                    {CommCol({ dataField: "delvZipcode", caption: "배송지우편번호" })}
                    {CommCol({ dataField: "delvAddress", caption: "배송지주소" })}
                    {CommCol({ dataField: "delvAddressDetail", caption: "배송지상세주소" })}
                    {StatCol({ dataField: "emailRecvYn", caption: "이메일수신동의", customCell: userAgreCell })}
                    {StatCol({ dataField: "smsRecvYn", caption: "SMS수신동의", customCell: userAgreCell })}
                    {CommCol({ dataField: "memshipCardNo", caption: "멤버쉽카드번호" })}
                    {StatCol({ dataField: "skHomeServiceMemYn", caption: "멤버쉽가입여부", customCell: userMembCell })}
                    {StatCol({ dataField: "payMethod", caption: "결제방식", customCell: userPaysCell })}
                    {StatCol({ dataField: "status", caption: "상태", customCell: userStatCell })}
                </PagingGrid>
            </div>
        </>
    );
};