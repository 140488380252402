import React from "react";
import { Button } from "devextreme-react";
import userIcon from "../../../../assets/ico/user-3.png";

export default function UserInfoHeadSection(props) {
    const { data, onPassBtnsClick } = props;

    return (
        <div className={'fw-profile-header'}>
            <figure className={`fw-user-icon-wrapper lvevl-${data.user_chek}`}>
                <img src={userIcon} alt="유저프로필이미지" />
            </figure>
            <div className={'fw-user-header-detail'}>
                <div className='fw-user-detail-item fw-column-flex-box'>
                    <div className={'fw-user-lv'}>{'사랑하는'}</div>
                    <div className='fw-user-name'><b>{data.user_name || data.id || "-"}</b> 님, 반갑습니다</div>
                    <div className='fw-user-id'>ID: {data.id || "확인불가"}</div>
                </div>
                <div className='fw-user-pass-wrapper fw-user-detail-item'>
                    {onPassBtnsClick &&
                        <Button
                            icon={'lock'}
                            text={'비밀번호 변경'}
                            onClick={onPassBtnsClick}
                        />
                    }
                </div>
            </div>
        </div>
    )
}