import UserAccoMana from "./acco/InfoMana-page";
import UserRfidMana from "./rfid/CardMana-page";
import UserCredMana from "./cred/CardMana-page";
import UserAuthMana from "./auth/InfoMana-page";
import UserCustMana from "./cust/InfoMana-page";
import UserInfoStat from "./info/UserInfostat";
import UserUsagstat from "./info/UserUsagstat";

export const user = ({ acce, code }) => {
    const cateSys = 'sys';
    const cateCom = 'com';
    const cateUse = 'user';

    const comp = {
        accoMana: <UserAccoMana acce={acce} cate={cateSys} code={`${code}1`} mainTitle={'관리자'} mainRole={'관리'} />,
        authMana: <UserAuthMana acce={acce} cate={cateSys} code={`${code}2`} mainTitle={'권한'} mainRole={'관리'} />,
        custMana: <UserCustMana acce={acce} cate={cateSys} code={`${code}3`} mainTitle={'고객'} mainRole={'현황'} />,
        rfidMana: <UserRfidMana acce={acce} cate={cateCom} code={`${code}4`} mainTitle={'RFID카드'} mainRole={'관리'} />,
        credMana: <UserCredMana acce={acce} cate={cateCom} code={`${code}5`} mainTitle={'결제카드'} mainRole={'현황'} />,
        infoMana: <UserInfoStat acce={acce} cate={cateUse} code={`${code}6`} mainTitle={'프로필'} mainRole={'관리'} />,
        usagStat: <UserUsagstat acce={acce} cate={cateUse} code={`${code}7`} mainTitle={'사용량'} mainRole={'조회'} />,
    }

    return comp;
}