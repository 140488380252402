// UserRfidMana - 사용자 관리 - RFID 관리
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { LoadPanel } from 'devextreme-react';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DateCol, DeciCol, NumbCol, StatCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { ContentHeader } from '../../../../script/components/header/Header';
import { UserRfidUpdate } from './CardMana-update';
import { fwUtil } from '../../../../script/util';

const rfidStatCell = (cellInfo) => {
    const value = cellInfo.value;
    let displayText = '-';
    let color = '#9E9E9E';

    if (value === "Y") {
        displayText = '사용';
        color = '#4CAF50';
    } else if (value === "N") {
        displayText = '비사용';
        color = '#FF5722';
    }

    return (
        <div className={'fw-user-level-column'}>
            <span className={`fw-chrg-state-text`} style={{ color: color }}>● {displayText}</span>
        </div>
    );
};

const rfidSendCell = (cellInfo) => {
    const value = cellInfo.value;
    let displayText = '-';
    let color = '#9E9E9E';

    if (value === "Y") {
        displayText = '전송';
        color = '#4CAF50';
    } else if (value === "N") {
        displayText = '비전송';
        color = '#FF5722';
    }

    return (
        <div className={'fw-user-level-column'}>
            <span className={`fw-chrg-state-text`} style={{ color: color }}>● {displayText}</span>
        </div>
    );
};

const rfidTypeCell = (cellInfo) => {
    const value = cellInfo.value;
    let displayText = '-';
    let color = '#9E9E9E';

    if (value === "P") {
        displayText = '개인';
        color = '#2196F3';
    } else if (value === "C") {
        displayText = '법인';
        color = '#3F51B5';
    }

    return (
        <div className={'fw-user-level-column'}>
            <span className={`fw-chrg-state-text`} style={{ color: color }}>● {displayText}</span>
        </div>
    );
};

export default function UserRfidMana(props) {
    const {
        acce, cate, code,
        mainTitle, mainRole,
    } = props;

    // api
    const infoMiddleApi = 'rfid/card/regist';
    const infoSearchApi = fwUtil.apis.create(acce, cate, infoMiddleApi, 'search');
    const infoUpdateApi = fwUtil.apis.create(acce, cate, infoMiddleApi, 'update');
    // 시스템
    const [loading, setLoading] = useState(true);
    // 헤더
    const title = `${mainTitle}${mainRole ? " " + mainRole : ""}`;
    // 권한
    const { authCheck } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const gridSize = "single";
    const mainKey = 'idToken';
    const gridRef = useRef(null);
    const [gridData, setGridData] = useState([]);
    const gridDataUpdate = useCallback((e) => setGridData(e), []);
    // 검색 조건    
    const [userName, setUserName] = useState("");
    const [rfidName, setRfidName] = useState("");
    const userNameChange = useCallback((e) => { setUserName(e) }, []);
    const rfidNameChange = useCallback((e) => { setRfidName(e) }, []);
    // 수정 팝업창
    const [infoUpdatePopupVisible, setInfoUpdatePopupVisible] = useState(false);
    const infoUpdatePopupHide = useCallback(() => { setInfoUpdatePopupVisible(false); }, []);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        authCheck(setLoading, setAuthList, code);
    }, [authCheck, setLoading, setAuthList, code]);

    // 조회
    const handleSearch = useCallback(async () => {
        const sendData = { userName: userName, rfidName: rfidName };
        await fwUtil.apis.search(infoSearchApi, sendData, gridDataUpdate, gridRef, null);
    }, [userName, rfidName, infoSearchApi, gridDataUpdate, gridRef]);

    useEffect(() => {
        handleSearch();
    }, [handleSearch]);

    // 추가 툴바 아이템
    const toolbarItems = useMemo(() => [
        { type: 'text', locate: 'before', caption: '소유자명', value: userName, valueChange: userNameChange, search: handleSearch },
        { type: 'text', locate: 'before', caption: '카드명', value: rfidName, valueChange: rfidNameChange, search: handleSearch },
        { type: 'btns', locate: 'before', icon: 'search', text: '검색', onClick: handleSearch }
    ], [userName, userNameChange, rfidName, rfidNameChange, handleSearch]);

    // 등록/수정/삭제 버튼 클릭
    const handleActionClick = useCallback((act) => {
        const selectedRowKey = fwUtil.grid.get.sKey(gridRef);

        if (selectedRowKey) {
            if (act === 'upd') {
                setInfoUpdatePopupVisible(true);
                return;
            }
        } else {
            fwUtil.aler.toast.warn('데이터를 선택 후 시도해주세요.');
        };

    }, [gridRef]);

    if (loading) { return <LoadPanel visible={true} /> };

    return (
        <>
            <ContentHeader icon={'fw-user'} title={title} />
            <div id={'fw-page-content'}>
                <PagingGrid
                    title={'RFID카드 현황'}
                    gridSize={gridSize}
                    mainKey={mainKey}
                    gridRef={gridRef}
                    gridDataSource={gridData}
                    upd={{ onClick: () => handleActionClick('upd'), disabled: authList.auth_upda === 0 }}
                    toolbar={{ default: false, items: toolbarItems }}
                    selection={'single'}
                >
                    {CommCol({ dataField: "companyName", caption: "회사명" })}
                    {CommCol({ dataField: "idToken", caption: "ID토큰" })}
                    {CommCol({ dataField: "parrentIdToken", caption: "상위 ID 토큰" })}
                    {CommCol({ dataField: "customerId", caption: "고객 ID" })}
                    {CommCol({ dataField: "customerName", caption: "고객명" })}
                    {CommCol({ dataField: "idTag", caption: "ID 태그" })}
                    {DateCol({ dataField: "expireDate", caption: "만료일자" })}
                    {StatCol({ dataField: "useYn", caption: "사용 여부", customCell: rfidStatCell })}
                    {CommCol({ dataField: "cardId", caption: "카드 ID" })}
                    {StatCol({ dataField: "status", caption: "상태", customCell: rfidTypeCell })}
                    {StatCol({ dataField: "sendYn", caption: "전송 여부", customCell: rfidSendCell })}
                    {DeciCol({ dataField: "wholeChargedEnergy", caption: "누적 충전량" })}
                    {NumbCol({ dataField: "wholeChargedPrice", caption: "누적 충전 금액" })}
                    {DeciCol({ dataField: "remainingFreeEnergy", caption: "잔여 무료 충전량" })}
                    {DeciCol({ dataField: "remainingAvailableChargingEnergy", caption: "남은 충전 가능량" })}
                    {DeciCol({ dataField: "accumulateChargingEnergy", caption: "누적 충전 에너지" })}
                    {DateCol({ dataField: "createDate", caption: "생성 일자", dataType: "datetime" })}
                    {CommCol({ dataField: "tokenGroup", caption: "토큰 그룹" })}
                    {DateCol({ dataField: "regDate", caption: "등록 일자", dataType: "datetime" })}
                </PagingGrid>
                <UserRfidUpdate
                    refresh={handleSearch}
                    mainGrid={gridRef}
                    updateApi={infoUpdateApi}
                    visible={infoUpdatePopupVisible}
                    hiding={infoUpdatePopupHide}
                />
            </div>
        </>
    );
};