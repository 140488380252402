export async function numbBeforeChange(e, integerLength, decimalLength) {
    if (!integerLength || (!decimalLength && decimalLength !== 0)) {
        return e;
    }
    let newValue = e;

    if (isNaN(newValue)) {
        newValue = 0;
    }
    
    let newValueStr = String(newValue);
    
    const decimalIndex = newValueStr.indexOf('.');
    if (decimalIndex !== -1) {
        // 정수 부분 추출
        const integerPartStr = newValueStr.substring(0, decimalIndex);
        // 소수 부분 추출 (소수점 포함)
        const decimalPartStr = newValueStr.substring(decimalIndex, decimalIndex + decimalLength + 1);
        
        // 정수 부분 길이 제한
        const trimmedIntegerPart = integerPartStr.length > integerLength 
            ? integerPartStr.substring(0, integerLength)
            : integerPartStr;
        
        // 최종 문자열 재조합
        newValueStr = trimmedIntegerPart + decimalPartStr;
    } else {
        // 소수점이 없는 경우
        if (newValueStr.length > integerLength) {
            newValueStr = newValueStr.substring(0, integerLength);
        }
    }

    return newValueStr;
}
