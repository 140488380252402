import React, { useState, useCallback } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupCard } from '../../../../script/components/popup/popup';
import { FormBool, FormText } from '../../../../script/components/form/form-items';
import { GroupItem } from 'devextreme-react/cjs/form';
import { ColorSelctor } from '../../../../script/components/color-selctor/color-selctor';
import { colorList } from '../../../../script/components/color-selctor/color-list';
import { PaysCard } from '../../../../script/components/pays-card/pays-card';
import { fwUtil } from '../../../../script/util';

const defaultData = {
    cred_name : '',
    cred_view : '',
    cred_numb : '0000000000000000',
    cred_colo : 'gray',
    cred_yesn : false,
};

export function UserCardInfoUpdate(props) {
    const {
        refresh,
        selectedData,
        updateApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState(defaultData);
    const dataUpdate = useCallback((e) => setData(e), [setData]);

    // 열릴 때
    const onShowing = useCallback(async() => {
        if (!selectedData) {
            fwUtil.ui.fwUtil.aler.toast.s1Erro();
            hiding();
        } else {
            const res = selectedData;
            const resData = {
                cred_name : res.cred_name || '',
                cred_view : res.cred_view || '',
                cred_numb : res.cred_numb || '0000000000000000',
                cred_colo : res.cred_colo || 'gray',
                cred_yesn : res.cred_yesn || false,              
            };

            dataUpdate(resData);
        }
    }, [hiding, selectedData, dataUpdate]);

    // 수정
    const updateField = (field) => (value) => {
        if (!data) return;

        dataUpdate({ ...data, [field]: value });
    };

    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true);
        const postData = {
            credName : data.cred_name || '',
            credView : data.cred_view || '',
            credNumb : data.cred_numb || '0000000000000000',
            credColo : data.cred_colo || 'gray',
            credYesn : data.cred_yesn ? 1 : 0,            
        };

        const res = await fwUtil.apis.return(updateApi, postData);
        if (res.status) {
            fwUtil.aler.toast.info(res.message);
            hiding();
            refresh();
        } else {
            fwUtil.aler.toast.erro(res?.message || '서버와 연결할 수 없습니다.');
        }
        setLoading(false);
    }, [data, updateApi, refresh, hiding]);

    return (
        <>
        <PopupCard
            title={'결제카드 수정'}       
            type={'수정'}
            loading={loading}
            visible={visible}
            showing={onShowing}
            hiding={hiding}
            onSubmit={onSubmit}
            width={isXSmall || isSmall ? 340 : 600 }
            cardContent={
                <PaysCard
                    data={data}
                    edit={false}
                />
            }
            colorSelctor={
                <ColorSelctor 
                    title={'카드색상'}
                    selcted={data?.cred_colo}
                    colors={colorList.card}
                    setColor={(color) => updateField('cred_colo')(color)}
                />
            } 
        >
            <GroupItem colCount={isXSmall || isSmall ? 1 : 2 }>
                {FormText({ value: data.cred_name, onValueChange: updateField('cred_name'), label: '카드이름', required: true, readOnly: true })}
                {FormText({ value: data.cred_view, onValueChange: updateField('cred_view'), label: '카드번호', required: true, readOnly: true })}
                {FormBool({ value: data.cred_yesn, onValueChange: updateField('cred_yesn'), label: '주 사용카드' })}    
            </GroupItem>
        </PopupCard>
        </>
    );
}