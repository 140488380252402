import React, { useState, useCallback } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupForm } from '../../../../script/components/popup/popup';
import { FormText } from '../../../../script/components/form/form-items';
import { GroupItem } from 'devextreme-react/cjs/form';
import { fwUtil } from '../../../../script/util';

const defaultData = {
    idToken: '',
    companyName: '',
    customerId: '',
    customerName: '',
    idTag: '',
    useYn: 'N',
    cardId: '',
    parrentIdToken: '',
    status: '',
    sendYn: '',
    createDate: '',
    tokenGroup: '',
    regDate: fwUtil.date.getsText.date(),    
};

export function UserRfidUpdate(props) {
    const {
        refresh,
        mainGrid,
        updateApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState(defaultData);
    const dataUpdate = useCallback((e) => setData(e), [setData]);

    // 열릴 때
    const onShowing = useCallback(() => {
        try {
            const seleData = fwUtil.grid.get.sData(mainGrid);

            if (seleData) {
                const targData = {
                    idToken: seleData.idToken,
                    companyName: seleData.companyName,
                    customerId: seleData.customerId,
                    customerName: seleData.customerName,
                    idTag: seleData.idTag,
                    useYn: seleData.useYn,
                    cardId: seleData.cardId,
                    parrentIdToken: seleData.parrentIdToken,
                    status: seleData.status,
                    sendYn: seleData.sendYn,
                    createDate: seleData.createDate,
                    tokenGroup: seleData.tokenGroup,
                    regDate: seleData.regDate,
                };
                dataUpdate(targData);
            } else {
                fwUtil.aler.toast.s1Erro();
                hiding();
            }
        } catch (error) {
            fwUtil.aler.toast.s1Erro();
            hiding();
        }
    }, [dataUpdate, mainGrid, hiding]);

    // 수정
    const updateField = (field) => (value) => {
        if (!data) return;

        dataUpdate({ ...data, [field]: value });
    };

    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true);
        const postData = {
            idToken: data.idToken || defaultData.idToken,
            idTag: data.idTag || defaultData.idTag,
        };

        await fwUtil.apis.submit(updateApi, postData, refresh);
        setLoading(false);
        hiding();
    }, [data, updateApi, refresh, hiding]);

    return (
        <>
            <PopupForm
                title={'회원카드 수정'}
                type={'수정'}
                loading={loading}
                visible={visible}
                showing={onShowing}
                hiding={hiding}
                onSubmit={onSubmit}
                width={isXSmall || isSmall ? 340 : 600}
            >
                <GroupItem>
                    {FormText({ value: data.companyName, onValueChange: updateField('companyName'), label: '회사명', readOnly: true })}
                    {FormText({ value: data.idToken, onValueChange: updateField('idToken'), label: 'ID토큰', readOnly: true })}
                    {FormText({ value: data.idTag, onValueChange: updateField('idTag'), label: 'ID태그', placeholder: '최대 20자' })}
                    {FormText({ value: data.customerId, onValueChange: updateField('customerId'), label: '사용자ID', readOnly: true })}
                    {FormText({ value: data.customerName, onValueChange: updateField('customerName'), label: '사용자명', readOnly: true })}
                </GroupItem>
            </PopupForm>
        </>
    );
}