// AnalDailStat - 정산/지표 분석 - 일자별 충전량 조회
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { LoadPanel } from 'devextreme-react';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid as MainGrid, PagingGrid as SubsGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DeciCol, NumbCol, StatCol, TimsCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { ContentHeader } from '../../../../script/components/header/Header';
import { fwUtil } from '../../../../script/util';
import { Summary } from 'devextreme-react/cjs/data-grid';
import { GroupSumItem } from '../../../../script/components/data-grid/data-grid-body/grid-body-item';

// 그룹 렌더링 함수
const dailyGroupRender = (data) => {
    if (!data || data.length < 1) {
        return null;
    }

    const roundToTwoDecimal = (num) => Math.round(num * 100) / 100;

    const kwhColumn = {
        ...data.summaryItems[0],
        value: roundToTwoDecimal(data.summaryItems[0].value),
    };
    const cntColumn = {
        ...data.summaryItems[1],
        value: roundToTwoDecimal(data.summaryItems[1].value),
    };
    const payColumn = {
        ...data.summaryItems[2],
        value: roundToTwoDecimal(data.summaryItems[2].value),
    };

    return (
        <div className='fw-custom-gruop-moni-dash'>
            <div className='fw-group-title'>
                {data.displayValue || '알수없음'}
            </div>
            <div className='fw-group-detail'>
                총 {kwhColumn.columnCaption} <b>{kwhColumn.value}</b> kWh |
            </div>
            <div className='fw-group-detail'>
                총 {cntColumn.columnCaption} <b>{cntColumn.value}</b> 회 | 
            </div>            
            <div className='fw-group-detail'>
                총 {payColumn.columnCaption} <b>{fwUtil.conv.to.krLocales(payColumn.value)}</b> 원
            </div>
        </div>
    );
};

const paysTypeCell = (paysInfo) => {
    const value = paysInfo.value;
    let displayText = '-';
    let color = '#9E9E9E';

    if (value === "R") {
        displayText = '환경부 로밍';
        color = '#4CAF50';
    } else if (value === "A") {
        displayText = '관리자 카드';
        color = '#FF9800';
    } else if (value === "Y") {
        displayText = '회원카드';
        color = '#2196F3';
    } else if (value === "N") {
        displayText = '비회원';
        color = '#F44336';
    } 

    return (
        <div className={'fw-user-level-column'}>
            <span className={`fw-chrg-state-text`} style={{ color: color }}>● {displayText}</span>
        </div>
    );
};

export default function AnalDailStat(props) {
    const {
        acce, cate, code,
        mainTitle, mainRole,
        subsTitle, subsRole,
    } = props;

    // api
    const mainMiddleApi = 'daily/header/status';
    const subsMiddleApi = 'daily/detail/status';
    const mainSearchApi = fwUtil.apis.create(acce, cate, mainMiddleApi, 'search');
    const subsSearchApi = fwUtil.apis.create(acce, cate, subsMiddleApi, 'search');   
    // 시스템
    const [loading, setLoading] = useState(true);
    const [pageLoading, setPageLoading] = useState(true);
    // 헤더
    const title = `${mainTitle}${mainRole ? " " + mainRole : ""}`;
    // 권한
    const { authCheck } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const gridSize = "dual";
    const mainKey = 'char_date';
    const subsKey = ['transactionId', 'chargePointId', 'connectorId', 'idTag'];
    const mainGridRef = useRef(null);
    const [mainGridData, setMainGridData] = useState([]);
    const mainGridDataUpdate = useCallback((e) => setMainGridData(e), []);
    const subsGridRef = useRef(null);
    const [subsGridData, setSubsGridData] = useState([]);
    const subsGridDataUpdate = useCallback((e) => setSubsGridData(e), []);
    // 검색 조건
    const [dateValue, setDateValue] = useState(fwUtil.date.getsText.range());
    const [stationName, setStationName] = useState("");
    const dateValueChange = useCallback((e) => { setDateValue([e.value[0], e.value[1]]) }, []);
    const stationNameChange = useCallback((e) => { setStationName(e) }, []);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        authCheck(setLoading, setAuthList, code);
    }, [authCheck, setLoading, setAuthList, code]);

    // 조회
    const mainHandleSearch = useCallback(async () => {
        setPageLoading(true);
        if (dateValue && dateValue[0] && dateValue[1]) {
            const sendData = { start: dateValue[0], compl: dateValue[1], stationName: stationName };
            await fwUtil.apis.search(mainSearchApi, sendData, mainGridDataUpdate, mainGridRef, subsGridRef);
        }
        setPageLoading(false);
    }, [dateValue, stationName, mainGridDataUpdate, mainGridRef, subsGridRef, mainSearchApi]);

    const subsHandleSearch = useCallback(async () => {
        setPageLoading(true);
        const keyData = fwUtil.grid.get.sKey(mainGridRef);
        if (keyData) {
            const sendData = { charDate: keyData, stationName: stationName  };
            await fwUtil.apis.search(subsSearchApi, sendData, subsGridDataUpdate, subsGridRef);
        }
        setPageLoading(false);
    }, [subsGridDataUpdate, mainGridRef, subsGridRef, subsSearchApi, stationName]);

    const handleSearch = useCallback(() => {
        mainHandleSearch();
    }, [mainHandleSearch]);

    useEffect(() => {
        handleSearch();
    }, [handleSearch]);

    const mainGridClick = useCallback(({ key }) => {
        if (key) {
            subsHandleSearch();
        }
    }, [subsHandleSearch]);

    // 추가 툴바 아이템
    const toolbarItems = useMemo(() => [
        { type: 'text', locate: 'before', caption: '사업장명', value: stationName, valueChange: stationNameChange, search: handleSearch },
        { type: 'btns', locate: 'before', icon: 'search', text: '검색', onClick: handleSearch }
    ], [stationName, stationNameChange, handleSearch]);

    if (loading) { return <LoadPanel visible={true} /> };

    return (
        <>
            <ContentHeader icon={'fw-anal'} title={title} />
            <LoadPanel visible={pageLoading} shadingColor='rgba(0, 0, 0, .4)'/>
            <div id={'fw-page-content'}>
                <MainGrid
                    title={mainTitle}
                    role={mainRole}
                    gridSize={gridSize}
                    mainKey={mainKey}
                    gridRef={mainGridRef}
                    gridDataSource={mainGridData}
                    onRowClick={mainGridClick}
                    dateValue={dateValue}
                    dateValueChange={dateValueChange}
                    duraYesn={true}
                    exp={{ disabled: authList.auth_dnlo === 0 }}
                    toolbar={{ default: true, items: toolbarItems }}
                    selection={'single'}
                >
                    {CommCol({ dataField: "char_year", caption: "년도", groupIndex: 0, groupRender: dailyGroupRender })}
                    {CommCol({ dataField: "char_mont", caption: "년월", groupIndex: 1, groupRender: dailyGroupRender })}
                    {CommCol({ dataField: "char_date", caption: "충전일자" })}
                    {DeciCol({ dataField: "char_powe", caption: "충전량", suffix: "kWh" })}
                    {NumbCol({ dataField: "char_qtys", caption: "충전횟수" })}
                    {DeciCol({ dataField: "char_pric", caption: "매출액", suffix: "(원)" })}
                    <Summary>
                        {GroupSumItem({ dataField: "char_powe", suffix: "kWh" })}
                        {GroupSumItem({ dataField: "char_qtys", suffix: "회" })}
                        {GroupSumItem({ dataField: "char_pric", suffix: "원" })}
                    </Summary>
                </MainGrid>
                <SubsGrid
                    title={subsTitle}
                    role={subsRole}
                    gridSize={gridSize}
                    mainKey={subsKey}
                    gridRef={subsGridRef}
                    gridDataSource={subsGridData}
                    exp={{ disabled: authList.auth_dnlo === 0 }}
                    toolbar={{ default: false }}
                    selection={'single'}
                >
                    {CommCol({ dataField: "station_name", caption: "충전소명", width: 140 })}
                    {CommCol({ dataField: "chargePointId", caption: "충전기ID", width: 200 })}
                    {CommCol({ dataField: "startTransaction", caption: "충전시작 시간", width: 200 })}
                    {CommCol({ dataField: "stopTransaction", caption: "충전종료 시간", width: 200 })}
                    {StatCol({ dataField: "payType", caption: "결제방식", customCell: paysTypeCell, width: 140 })}
                    {TimsCol({ dataField: "tran_dura", caption: "충전시간", width: 120 })}
                    {DeciCol({ dataField: "usePower", caption: "충전량", suffix: "kWh", width: 200 })}
                    {DeciCol({ dataField: "resultPrice", caption: "충전금액", suffix: "원" })}
                </SubsGrid>
            </div>
        </>
    );
};