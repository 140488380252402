// ChrgStusMana - 충전기 관리
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { getCharPageStat } from '../../../../../api/cms/set-chrg';
import { LoadPanel } from 'devextreme-react';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid as MainGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DateCol, DeciCol, StatCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { ContentHeader } from '../../../../script/components/header/Header';
import { StusManaUpdate } from './StusMana-update';
import { fwUtil } from '../../../../script/util';

const chrgStatCell = (cellInfo) => {
    const value = cellInfo.value;
    let displayText = '-';
    let color = '#9E9E9E';

    displayText = fwUtil.conv.to.krChrgStat(value);
    color = fwUtil.conv.to.chrgColors(value);

    return (
        <div className={'fw-user-level-column'}>
            <span className={`fw-chrg-state-text`} style={{ color: color }}>● {displayText}</span>
        </div>
    );
};
export default function ChrgStusMana(props) {
    const {
        acce, cate, code,
        mainTitle, mainRole,
    } = props;

    // api
    const mainMiddleApi = 'charger/status/register';
    const mainSearchApi = fwUtil.apis.create(acce, cate, mainMiddleApi, 'search');
    const mainUpdateApi = fwUtil.apis.create(acce, cate, mainMiddleApi, 'update');
    // 시스템
    const [loading, setLoading] = useState(true);
    // 헤더
    const title = `${mainTitle}${mainRole ? " " + mainRole : ""}`;
    // 권한
    const { authCheck } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const gridSize = "single";
    const mainKey = ['id', 'station_id', 'connector_id'];
    const mainGridRef = useRef(null);
    const [mainGridData, setMainGridData] = useState([]);
    const mainGridDataUpdate = useCallback((e) => setMainGridData(e), []);
    // 검색 조건
    const [stationInfo, setStationInfo] = useState('');
    const [charIdno, setCharIdno] = useState("");
    const [stationList, setStationList] = useState([]);
    const stationInfoChange = useCallback((e) => { setStationInfo(e) }, []);
    const charIdnoChange = useCallback((e) => { setCharIdno(e) }, []);
    const stationListChange = useCallback((e) => { setStationList(e) }, []);
    // 수정 팝업창
    const [mainUpdatePopupVisible, setMainUpdatePopupVisible] = useState(false);
    const mainUpdatePopupHide = useCallback(() => { setMainUpdatePopupVisible(false); }, []);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        authCheck(setLoading, setAuthList, code);
    }, [authCheck, setLoading, setAuthList, code]);

    const fetchSet = useCallback(async () => {
        try {
            const [stationListResult] = await Promise.all([getCharPageStat()]);
            stationListChange(stationListResult);
            stationInfoChange(stationListResult[0]);
        } catch (error) {
            console.error('Error fetching data:', error);
            fwUtil.aler.toast.nsWarn();;
        }
    }, [stationInfoChange, stationListChange]);

    // 조회
    const mainHandleSearch = useCallback(async () => {
        const sendData = { stationName: stationInfo.station_name === "전체" ? "" : stationInfo.station_name, charIdno: charIdno };
        await fwUtil.apis.search(mainSearchApi, sendData, mainGridDataUpdate, mainGridRef);
    }, [stationInfo, charIdno, mainGridDataUpdate, mainGridRef, mainSearchApi]);

    useEffect(() => {
        fetchSet();
    }, [fetchSet]);

    useEffect(() => {
        stationInfo && mainHandleSearch();
    }, [mainHandleSearch, stationInfo])

    // 추가 툴바 아이템
    const toolbarItems = useMemo(() => [
        { type: 'selc', locate: 'before', caption: '충전소', value: stationInfo, valueChange: stationInfoChange, search: mainHandleSearch, items: stationList, displayExpr: 'station_name', width: 170 },
        { type: 'text', locate: 'before', caption: '충전기ID', value: charIdno, valueChange: charIdnoChange, search: mainHandleSearch },
        { type: 'btns', locate: 'before', icon: 'search', text: '검색', onClick: mainHandleSearch }
    ], [stationInfo, stationInfoChange, stationList, charIdno, charIdnoChange, mainHandleSearch]);

    // 등록/수정/삭제 버튼 클릭
    const handleActionClick = useCallback((type, act) => {
        const mainSelectedRowKey = fwUtil.grid.get.sKey(mainGridRef);

        const handleMainAction = () => {
            switch (act) {
                case 'upd':
                    setMainUpdatePopupVisible(true);
                    break;
                default:
                    break;
            }
        };

        if (!mainSelectedRowKey) {
            fwUtil.aler.toast.warn('관리코드를 선택 후 시도해주세요.');
            return;
        }

        if (type === 'main') {
            handleMainAction();
        }

    }, [mainGridRef]);

    if (loading) { return <LoadPanel visible={true} /> };

    return (
        <>
            <ContentHeader icon={'fw-chrg'} title={title} />
            <div id={'fw-page-content'}>
                <MainGrid
                    title={'충전기 상태 현황'}
                    gridSize={gridSize}
                    mainKey={mainKey}
                    gridRef={mainGridRef}
                    gridDataSource={mainGridData}
                    upd={{ onClick: () => handleActionClick('main', 'upd'), disabled: authList.auth_upda === 0 }}
                    exp={{ disabled: authList.auth_dnlo === 0 }}
                    toolbar={{ default: false, items: toolbarItems }}
                    selection={'single'}
                >
                    {CommCol({ dataField: "station_name", caption: "충전소명" })}
                    {CommCol({ dataField: "id", caption: "충전기ID" })}
                    {CommCol({ dataField: "connector_id", caption: "커넥터ID" })}
                    {CommCol({ dataField: "env_id", caption: "환경부용 커넥터ID" })}
                    {StatCol({ dataField: "state", caption: "상태", customCell: chrgStatCell })}
                    {CommCol({ dataField: "errorCode", caption: "에러코드" })}
                    {DeciCol({ dataField: "volt", caption: "볼트", suffix: "V" })}
                    {DeciCol({ dataField: "ampere", caption: "암페어", suffix: "A" })}
                    {DeciCol({ dataField: "kW", caption: "kW", suffix: "kW" })}
                    {DeciCol({ dataField: "kWh", caption: "kWh", suffix: "kWh" })}
                    {DateCol({ dataField: "last_upt_date", caption: "최종 업데이트 날짜" })}
                </MainGrid>
                <StusManaUpdate
                    refresh={mainHandleSearch}
                    mainGrid={mainGridRef}
                    updateApi={mainUpdateApi}
                    visible={mainUpdatePopupVisible}
                    hiding={mainUpdatePopupHide}
                />
            </div>
        </>
    );
};