import React, { useCallback, useRef, useState } from 'react';
import LoadIndicator from 'devextreme-react/load-indicator';
import Form, { GroupItem } from 'devextreme-react/form';
import { CheckBox, FileManager, TabPanel, TextArea, TextBox } from 'devextreme-react';
import { Column, ContextMenu, Details, FileSelectionItem, Item, ItemView, Permissions, Toolbar } from 'devextreme-react/cjs/file-manager';
import { Button, Popup, ScrollView, ValidationGroup } from 'devextreme-react';
import { LicenseComp, licenseTabData, PopupToolbar } from './popup-item';
import { DaumPostcodeEmbed } from 'react-daum-postcode';
import { FormPass } from '../form/form-items';
import { getAlertData } from './popup-util';
import parse from 'html-react-parser';
import { fwUtil } from '../../util';
import BoardEditor from '../board/board-editor';
import mapUtil from '../map/map-util';
import './popup.scss';
import { useScreenSize } from '../../../../utils/media-query';

const { kakao } = window;

// 알림 다이얼로그
const PopupAler = (props) => {
    const {
        grid,
        visible, hiding, confirm,
        message,
    } = props;

    const [alertData, setAlertData] = useState([]);

    const onShowing = () => {
        if (grid) {
            const selectedData = fwUtil.grid.get.sKey(grid);
            if (selectedData) {
                setAlertData(getAlertData(selectedData));
            } else {
                setAlertData([]);
                hiding();
            }
        }
    };

    return (
        <Popup
            visible={visible}
            onHiding={hiding}
            width={'auto'}
            height={'auto'}
            showCloseButton={false}
            wrapperAttr={{ class: 'fw-alert-popup' }}
            onShowing={onShowing}
            hideOnOutsideClick
        >
            <div className="fw-popup-content fw-column-flex-box">
                {alertData && alertData.map((item, index) => (
                    <span className="alert-key" key={index}>
                        {typeof item === 'object'
                            ? Object.values(item).map((v, i) => <span key={i}>{v} </span>)
                            : item
                        }
                    </span>
                ))}
                <span className="alert-message">{message}</span>
                <div className="alert-button-wrapper fw-row-flex-box">
                    <Button
                        text="확인"
                        stylingMode="contained"
                        onClick={confirm}
                    />
                    <Button
                        text="취소"
                        className="fw-cancle-button"
                        stylingMode="outlined"
                        onClick={hiding}
                    />
                </div>
            </div>
        </Popup>
    );
};

// 확인 다이얼로그
const PopupConf = (props) => {
    const {
        info, confirmText, placeholder, title,
        visible, hiding, confirm, closeHide
    } = props;

    const confirmBtnText = confirmText || "확인";
    const hideOnOutsideClick = fwUtil.conv.tern(true, !closeHide);
    const [conf, setConf] = useState("");
    const confChange = useCallback((e) => { setConf(e) }, []);

    const onConfirmClick = useCallback(() => {
        const confCheck = conf === confirmText;
        if (confCheck) {
            confirm();
        } else {
            fwUtil.aler.toast.erro(placeholder);
        }
    }, [conf, confirmText, confirm, placeholder]);

    return (
        <Popup
            visible={visible}
            onHiding={hiding}
            title={title}
            width={'auto'}
            height={'auto'}
            showCloseButton={false}
            wrapperAttr={{ class: 'fw-confirm-popup' }}
            hideOnOutsideClick={hideOnOutsideClick}
        >
            <div className="fw-popup-content fw-column-flex-box">
                <div className="info-message">{info}</div>
                <div className='confirm-input-wrapper'>
                    <TextBox
                        value={conf}
                        onValueChange={confChange}
                        placeholder={placeholder}
                        stylingMode='outlined'
                    />
                </div>
                <div className="confirm-button-wrapper fw-row-flex-box">
                    <Button
                        text={confirmBtnText}
                        stylingMode="contained"
                        type='default'
                        onClick={onConfirmClick}
                    />
                    {!closeHide &&
                        <Button
                            text="취소"
                            className="fw-cancle-button"
                            stylingMode="outlined"
                            type='default'
                            onClick={hiding}
                        />
                    }
                </div>
            </div>
        </Popup>
    );
};

// 경고 다이얼로그
const PopupWarn = (props) => {
    const {
        warning,
        visible, hiding, confirm, confirmText,
        message, closeHide
    } = props;

    const confirmBtnText = confirmText || "확인";
    const hideOnOutsideClick = fwUtil.conv.tern(true, !closeHide);

    return (
        <Popup
            visible={visible}
            onHiding={hiding}
            width={'auto'}
            height={'auto'}
            showCloseButton={false}
            wrapperAttr={{ class: 'fw-warning-popup' }}
            hideOnOutsideClick={hideOnOutsideClick}
        >
            <div className="fw-popup-content fw-column-flex-box">
                <span className="warning-message">{message}</span>
                <span className="warning-key">
                    {warning}
                </span>
                <div className="warning-button-wrapper fw-row-flex-box">
                    <Button
                        text={confirmBtnText}
                        stylingMode="contained"
                        onClick={confirm}
                    />
                    {!closeHide &&
                        <Button
                            text="취소"
                            className="fw-cancle-button"
                            stylingMode="outlined"
                            onClick={hiding}
                        />
                    }
                </div>
            </div>
        </Popup>
    );
};

// 주소 다이얼로그
const PopupAddr = (props) => {
    const {
        visible, hiding, confirm
    } = props;
    const oncomplete = (data) => {
        hiding();
        confirm(data);
    };

    if (!visible) {
        return;
    }

    return (
        <>
            <Popup
                title='주소찾기'
                visible={visible}
                onHiding={hiding}
                width={'auto'}
                height={'auto'}
                showCloseButton={true}
                wrapperAttr={{ class: 'fw-daum-popup' }}
                hideOnOutsideClick
            >
                <div className="fw-popup-content">
                    <DaumPostcodeEmbed
                        style={{
                            width: '100%',
                            height: '100%',
                        }}
                        onComplete={oncomplete}
                    />
                </div>
            </Popup>
        </>
    );
};

// 지도 다이얼로그
const PopupMaps = (props) => {
    const {
        visible, hiding, confirm,
        defaultAddr,
        mapInstance,
        setMapInstance,
        zoom,
    } = props;

    const onShown = useCallback(() => {
        if (!kakao || !defaultAddr) {
            fwUtil.aler.toast.erro('좌표 정보를 받아오는 데 실패하였습니다.');
            hiding();
            return;
        };

        if (mapInstance) {
            return;
        }

        const container = document.getElementById('fw-popup-maps');
        const options = {
            center: new kakao.maps.LatLng(33.450701, 126.570667),
            level: 3
        };

        if (container) {
            // instance set
            const map = new kakao.maps.Map(container, options);
            const geocoder = new kakao.maps.services.Geocoder();
            searchAddrFromCoords(map.getCenter(), displayCenterInfo);

            // 중심 좌표나 확대 수준이 변경됐을 때 지도 중심 좌표에 대한 주소 정보를 표시하도록 이벤트를 등록합니다
            kakao.maps.event.addListener(map, 'idle', function () {
                searchAddrFromCoords(map.getCenter(), displayCenterInfo);
            });

            geocoder.addressSearch(defaultAddr, function (result, status) {
                // 정상적으로 검색이 완료됐으면 
                if (status === kakao.maps.services.Status.OK) {
                    const coords = new kakao.maps.LatLng(result[0].y, result[0].x);
                    const marker = new kakao.maps.Marker({
                        map: map,
                        position: coords
                    });
                    marker.setMap(map);
                    map.setCenter(coords);

                    kakao.maps.event.addListener(marker, 'click', function () {
                        // 마커 마우스 이벤트
                        searchDetailAddrFromCoords(coords, function (result, status) {
                            if (status === kakao.maps.services.Status.OK) {
                                const postData = {
                                    addr: result[0]?.road_address?.address_name || result[0]?.address?.address_name,
                                    xint: coords?.getLng(),
                                    yint: coords?.getLat(),
                                    adda: result[0]?.road_address?.region_1depth_name || result[0]?.address?.region_1depth_name,
                                    addb: result[0]?.road_address?.region_2depth_name || result[0]?.address?.region_2depth_name,
                                }
                                confirm && confirm(postData);
                            }
                        });
                    });
                }
            });

            // 지도를 클릭했을 때 클릭 위치 좌표에 대한 주소정보를 표시하도록 이벤트를 등록합니다
            kakao.maps.event.addListener(map, 'click', function (mouseEvent) {
                searchDetailAddrFromCoords(mouseEvent.latLng, function (result, status) {
                    if (status === kakao.maps.services.Status.OK) {
                        const postData = {
                            addr: result[0]?.road_address?.address_name || result[0]?.address?.address_name,
                            xint: mouseEvent?.latLng?.getLng(),
                            yint: mouseEvent?.latLng?.getLat(),
                            adda: result[0]?.road_address?.region_1depth_name || result[0]?.address?.region_1depth_name,
                            addb: result[0]?.road_address?.region_2depth_name || result[0]?.address?.region_2depth_name,
                        }
                        confirm && confirm(postData);
                    }
                });
            });

            function searchAddrFromCoords(coords, callback) {
                // 좌표로 행정동 주소 정보를 요청합니다
                geocoder.coord2RegionCode(coords.getLng(), coords.getLat(), callback);
            }

            function searchDetailAddrFromCoords(coords, callback) {
                // 좌표로 법정동 상세 주소 정보를 요청합니다
                geocoder.coord2Address(coords.getLng(), coords.getLat(), callback);
            }

            function displayCenterInfo(result, status) {
                if (status === kakao.maps.services.Status.OK) {
                    var infoDiv = document.getElementById('centerAddr');

                    if (infoDiv) {
                        for (var i = 0; i < result.length; i++) {
                            // 행정동의 region_type 값은 'H' 이므로
                            if (result[i].region_type === 'H') {
                                infoDiv.innerHTML = result[i].address_name;
                                break;
                            }
                        }
                    }
                }
            }

            setMapInstance(map);

            mapUtil.set.zoom(map, zoom);
        }
    }, [setMapInstance, zoom, hiding, defaultAddr, mapInstance, confirm])

    if (!visible) {
        return;
    }

    return (
        <>
            <Popup
                title='위치지정'
                visible={visible}
                onHiding={hiding}
                onShown={onShown}
                width={'90%'}
                height={'90%'}
                maxWidth={700}
                maxHeight={700}
                showCloseButton={true}
                wrapperAttr={{ class: 'fw-maps-popup' }}
                hideOnOutsideClick
            >
                <div className="fw-popup-content">
                    <div id="fw-popup-maps"></div>
                    <div className="fw-maps-hAddr">
                        <span className="title">🗺️ 지도중심기준 행정동 주소정보</span>
                        <span id="centerAddr"></span>
                    </div>
                </div>
            </Popup>
        </>
    );
};


// 비밀번호 변경 다이얼로그
const PopupPass = (props) => {
    const {
        data, updateField,
        visible, hiding, isXSmall
    } = props;

    const onSubmit = useCallback(() => {
        //비밀번호 변경 로직
        const postData = {

        };
    }, []);

    // 동작 버튼 클릭
    const confirm = useCallback(({ validationGroup }) => {
        const validationResult = fwUtil.data.valdit(validationGroup);
        if (validationResult) {
            onSubmit();
        }
    }, [onSubmit]);

    return (
        <Popup
            title='비밀번호 변경'
            visible={visible}
            onHiding={hiding}
            width={isXSmall ? 350 : 400}
            height={'auto'}
            maxWidth={'100%'}
            showCloseButton={false}
            wrapperAttr={{ class: 'fw-pass-popup' }}
            hideOnOutsideClick
        >
            <div className="fw-popup-content fw-column-flex-box">
                <ValidationGroup>
                    <Form>
                        <GroupItem cssClass='fw-current-password-wrapper'>
                            {FormPass({ value: data.curr_pass, onValueChange: updateField('curr_pass'), label: '현재 비밀번호', required: true })}
                        </GroupItem>
                        {FormPass({ value: data.pass_word, onValueChange: updateField('pass_word'), label: '비밀번호', required: true })}
                        {FormPass({ value: data.conf_pass, onValueChange: updateField('conf_pass'), label: '비밀번호 확인', required: true, confirm: true, password: data.pass_word })}
                    </Form>
                </ValidationGroup>
                <PopupToolbar
                    type={'수정'}
                    onSubmit={confirm}
                    onClose={hiding}
                />
            </div>
        </Popup>
    );
};

const PopupForm = (props) => {
    const {
        title, type, width, loading,
        visible, hiding, showing, shown, onSubmit,
        outSideClick, closeButton, renderDefer,
        children, additionalChild
    } = props;

    const { isXSmall, isSmall } = useScreenSize();
    const ValidationRef = useRef(null);
    const hideOutsideClick = fwUtil.conv.tern(false, outSideClick);
    const showCloseButton = fwUtil.conv.tern(true, closeButton);
    const deferRendering = fwUtil.conv.tern(true, renderDefer);

    // 동작 버튼 클릭
    const onActionClick = ({ validationGroup }) => {
        const validationResult = fwUtil.data.valdit(validationGroup);
        if (validationResult) {
            onSubmit();
        }
    };

    return (
        <Popup
            visible={visible}
            onHiding={hiding}
            onShowing={showing}
            onShown={shown}
            title={title}
            width={width}
            height={'auto'}
            maxHeight={isXSmall || isSmall ? '100%' : '95%'}
            enableBodyScroll={false}
            hideOnOutsideClick={hideOutsideClick}
            showCloseButton={showCloseButton}
            deferRendering={deferRendering}
        >
            {loading &&
                <div className={loading ? 'fw-popup-loading-overlay visible' : 'fw-popup-loading-overlay hidden'}>
                    <LoadIndicator width={'64px'} height={'64px'} visible={true} />
                </div>
            }
            <ScrollView width="100%" height="100%" >
                <ValidationGroup ref={ValidationRef} >
                    <div className={'fw-popup-content fw-form-popup fw-column-flex-box'}>
                        <div className={`fw-popup-form fw-column-flex-box`}>
                            <form>
                                <Form>
                                    {children}
                                </Form>
                            </form>
                            <div className={'fw-additional-child-wrapper'}>
                                {additionalChild}
                            </div>
                        </div>
                        {onSubmit &&
                            <PopupToolbar
                                type={type}
                                onSubmit={onActionClick}
                                onClose={hiding}
                            />
                        }
                    </div>
                </ValidationGroup>
            </ScrollView>
        </Popup>
    )
};

const PopupGrid = (props) => {
    const {
        title, type, width, loading,
        visible, hiding, showing, shown, onSubmit,
        outSideClick, closeButton, renderDefer,
        children,
    } = props;

    const hideOutsideClick = fwUtil.conv.tern(false, outSideClick);
    const showCloseButton = fwUtil.conv.tern(true, closeButton);
    const deferRendering = fwUtil.conv.tern(true, renderDefer);

    // 동작 버튼 클릭
    const onActionClick = ({ validationGroup }) => {
        const validationResult = fwUtil.data.valdit(validationGroup);
        if (validationResult) {
            onSubmit();
        }
    };

    return (
        <Popup
            visible={visible}
            onHiding={hiding}
            onShowing={showing}
            onShown={shown}
            title={title}
            width={width}
            height={'auto'}
            maxHeight={'90%'}
            enableBodyScroll={false}
            hideOnOutsideClick={hideOutsideClick}
            showCloseButton={showCloseButton}
            deferRendering={deferRendering}
        >
            {loading &&
                <div className={loading ? 'fw-popup-loading-overlay visible' : 'fw-popup-loading-overlay hidden'}>
                    <LoadIndicator width={'64px'} height={'64px'} visible={true} />
                </div>
            }
            <ScrollView width="100%" height="100%" >
                <ValidationGroup>
                    <div className={'fw-popup-content fw-grid-popup fw-column-flex-box'}>
                        {children}
                        {type &&
                            <PopupToolbar
                                type={type}
                                onSubmit={onActionClick}
                                onClose={hiding}
                            />
                        }
                    </div>
                </ValidationGroup>
            </ScrollView>
        </Popup>
    )
};

const PopupFile = (props) => {
    const {
        title, width, loading, setLoading,
        visible, hiding, showing, shown,
        outSideClick, closeButton, renderDefer,
        fileManagerRef, fileSystemProvider, allowedFileExtensions, rootFileName,
        onFileUploading, onItemDeleting, onItemRenaming
    } = props;

    const hideOutsideClick = fwUtil.conv.tern(false, outSideClick);
    const showCloseButton = fwUtil.conv.tern(true, closeButton);
    const deferRendering = fwUtil.conv.tern(false, renderDefer);

    const onShown = useCallback(async () => {
        shown && shown();
        await fileManagerRef?.current?.instance?.repaint();
        await setLoading(false);
    }, [shown, fileManagerRef, setLoading]);

    return (
        <Popup
            visible={visible}
            onHiding={hiding}
            onShowing={showing}
            onShown={onShown}
            title={title}
            width={width}
            height={'auto'}
            maxHeight={'90%'}
            enableBodyScroll={false}
            hideOnOutsideClick={hideOutsideClick}
            showCloseButton={showCloseButton}
            deferRendering={deferRendering}
        >
            {loading &&
                <div className={loading ? 'fw-popup-loading-overlay visible' : 'fw-popup-loading-overlay hidden'}>
                    <LoadIndicator width={'64px'} height={'64px'} visible />
                </div>
            }
            <div className='fw-file-manager-wrapper'>
                <FileManager
                    ref={fileManagerRef}
                    fileSystemProvider={fileSystemProvider}
                    allowedFileExtensions={allowedFileExtensions}
                    rootFolderName={rootFileName}
                    onFileUploading={onFileUploading}
                    onItemDeleting={onItemDeleting}
                    onItemRenaming={onItemRenaming}
                >
                    <Permissions
                        delete
                        rename
                        upload
                        download
                    />
                    <ItemView showParentFolder={false}>
                        <Details>
                            <Column dataField="thumbnail" />
                            <Column dataField="name" caption='파일명' />
                            <Column dataField="dateModified" caption='수정일자' />
                            <Column dataField="size" caption='크기' />
                        </Details>
                    </ItemView>
                    <Toolbar>
                        <Item name="showNavPane" visible />
                        <Item name="separator" />
                        <Item name="upload" text='업로드' options={{ hint: "Shift 또는 Ctrl로 다중선택" }} />
                        <Item name="refresh" options={{ hint: "새로고침" }} />
                        <Item
                            name="separator"
                            location="after"
                        />
                        <Item name="switchView" />
                        <FileSelectionItem name="rename" text='이름변경' />
                        <FileSelectionItem name="separator" />
                        <FileSelectionItem name="delete" text='삭제' />
                        <FileSelectionItem name="separator" />
                        <FileSelectionItem name="download" text='다운로드' />
                        <FileSelectionItem name="clearSelection" text='선택헤제' />
                    </Toolbar>
                    <ContextMenu>
                        <Item name="rename" text='이름변경' beginGroup />
                        <Item name="delete" text='삭제' />
                        <Item name="download" text='다운로드' />
                    </ContextMenu>
                </FileManager>
            </div>
        </Popup>
    )
};

const PopupRepo = (props) => {
    const {
        title, width, loading,
        visible, hiding, showing, shown,
        outSideClick, closeButton, renderDefer,
    } = props;

    const hideOutsideClick = fwUtil.conv.tern(false, outSideClick);
    const showCloseButton = fwUtil.conv.tern(true, closeButton);
    const deferRendering = fwUtil.conv.tern(true, renderDefer);

    return (
        <Popup
            visible={visible}
            onHiding={hiding}
            onShowing={showing}
            onShown={shown}
            title={title}
            width={width}
            height={'auto'}
            maxHeight={'90%'}
            enableBodyScroll={false}
            hideOnOutsideClick={hideOutsideClick}
            showCloseButton={showCloseButton}
            deferRendering={deferRendering}
        >
            {loading &&
                <div className={loading ? 'fw-popup-loading-overlay visible' : 'fw-popup-loading-overlay hidden'}>
                    <LoadIndicator width={'64px'} height={'64px'} visible={true} />
                </div>
            }
            <ScrollView width="100%" height="100%" >

            </ScrollView>
        </Popup>
    )
};

const PopupCard = (props) => {
    const {
        title, type, width, loading,
        visible, hiding, showing, shown, onSubmit,
        outSideClick, closeButton, renderDefer,
        children, cardContent, colorSelctor,
    } = props;

    const ValidationRef = useRef(null);
    const hideOutsideClick = fwUtil.conv.tern(false, outSideClick);
    const showCloseButton = fwUtil.conv.tern(true, closeButton);
    const deferRendering = fwUtil.conv.tern(true, renderDefer);

    // 동작 버튼 클릭
    const onActionClick = ({ validationGroup }) => {
        const validationResult = fwUtil.data.valdit(validationGroup);
        if (validationResult) {
            onSubmit();
        }
    };

    return (
        <Popup
            visible={visible}
            onHiding={hiding}
            onShowing={showing}
            onShown={shown}
            title={title}
            width={width}
            height={'auto'}
            maxHeight={'95%'}
            enableBodyScroll={false}
            hideOnOutsideClick={hideOutsideClick}
            showCloseButton={showCloseButton}
            deferRendering={deferRendering}
        >
            {loading &&
                <div className={loading ? 'fw-popup-loading-overlay visible' : 'fw-popup-loading-overlay hidden'}>
                    <LoadIndicator width={'64px'} height={'64px'} visible={true} />
                </div>
            }
            <ScrollView width="100%" height="100%" >
                <ValidationGroup ref={ValidationRef} >
                    <div className={'fw-popup-content fw-form-popup fw-card-popup fw-column-flex-box'}>
                        <div className={`fw-popup-form fw-column-flex-box`}>
                            <div className={`fw-popup-form-card-content-wrapper`}>
                                {cardContent}
                            </div>
                            <form>
                                <Form>
                                    {children}
                                </Form>
                            </form>
                            {colorSelctor}
                        </div>
                        {onSubmit &&
                            <PopupToolbar
                                type={type}
                                onSubmit={onActionClick}
                                onClose={hiding}
                            />
                        }
                    </div>
                </ValidationGroup>
            </ScrollView>
        </Popup>
    )
};

const PopupBoar = (props) => {
    const {
        title, loading,
        visible, hiding, shown,
        viewCount, author, regiDate, content, contentUpdate,
        update, isEditing, setIsEditing,
        reply, children,
    } = props;

    const updateField = (field) => (value) => {
        if (!reply || !reply.value || !reply.setValue) return;
        reply.setValue({ ...reply.value, [field]: value });
    };

    return (
        <Popup
            visible={visible}
            onHiding={hiding}
            onShown={shown}
            title={title}
            width={'100%'}
            height={'100%'}
            maxHeight={'100%'}
            showCloseButton={true}
        >
            {(loading || !content) &&
                <div className={loading ? 'fw-popup-loading-overlay visible' : 'fw-popup-loading-overlay hidden'}>
                    <LoadIndicator width={'84px'} height={'84px'} visible={true} />
                </div>
            }
            {content &&
                <ScrollView width="100%" height="100%" >
                    <div className={'fw-popup-content fw-board-popup fw-column-flex-box'}>
                        <div className={'board-popup-header'}>
                            <div className={'board-title'}>
                                <div className={'title-label'}>제목</div>
                                <div className={'title-text'}>{title}</div>
                            </div>
                            {<div className={'board-views'}>조회수 {viewCount || 0}</div>}
                        </div>
                        <div className={'board-popup-detail'}>
                            <div className={'board-author'}>
                                <div className={'author-label'}>작성자</div>
                                <div className={'author-text'}>{author}</div>
                            </div>
                            <div className={'board-regidate'}>
                                <div className={'regidate-label'}>작성일</div>
                                <div className={'regidate-text'}>{regiDate}</div>
                            </div>
                        </div>
                        {isEditing && contentUpdate &&
                            <BoardEditor value={content} valueChange={contentUpdate} />
                        }
                        {!isEditing &&
                            <div className={'board-popup-content'}>
                                {parse(content)}
                            </div>
                        }
                        <div className={'board-popup-toolbar'}>
                            {update && !isEditing &&
                                <Button stylingMode='contained' type='default' text='수정' onClick={() => setIsEditing(true)} />
                            }
                            {update && isEditing &&
                                <Button stylingMode='contained' type='normal' text='취소' onClick={() => setIsEditing(false)} />
                            }
                            {update && isEditing &&
                                <Button stylingMode='contained' type='default' icon='save' text='저장' onClick={update} />
                            }
                            <Button stylingMode='contained' type='normal' text='닫기' onClick={hiding} />
                        </div>
                        {reply &&
                            <div className={'board-popup-reply'}>
                                <div className={'board-popup-reply-input'}>
                                    <div className='reply-input-part'>
                                        <TextArea
                                            value={reply.value.repl_cont}
                                            onValueChange={updateField('repl_cont')}
                                            height={80}
                                            stylingMode='outlined'
                                            mode='text'
                                            maxLength={500}
                                            placeholder='댓글 입력 (최대 500자)'
                                        />
                                    </div>
                                    <div className='reply-submit-part'>
                                        <CheckBox
                                            text='비밀댓글'
                                            value={reply.value.secr_yesn}
                                            onValueChange={updateField('secr_yesn')}
                                        />
                                        <Button stylingMode='contained' type='default' icon='edit' text='댓글작성' onClick={reply.submit} disabled={reply.disabled} />
                                    </div>
                                </div>
                                <div className={'board-popup-reply-list'}>
                                    {children}
                                </div>
                            </div>
                        }
                    </div>
                </ScrollView>
            }
        </Popup>
    )
};

const PopupInfo = (props) => {
    const {
        fullSize, loading,
        visible, hiding,
        outSideClick, closeButton, renderDefer,
    } = props;

    const width = fullSize ? '100%' : 500;
    const height = fullSize ? '100%' : 'auto';
    const hideOutsideClick = fwUtil.conv.tern(false, outSideClick);
    const showCloseButton = fwUtil.conv.tern(true, closeButton);
    const deferRendering = fwUtil.conv.tern(true, renderDefer);

    const itemTitleRender = (item) => <span>{item.titlName || '항목없음'}</span>;

    return (
        <Popup
            visible={visible}
            onHiding={hiding}
            title={'앱 정보'}
            width={width}
            height={height}
            enableBodyScroll={false}
            hideOnOutsideClick={hideOutsideClick}
            showCloseButton={showCloseButton}
            deferRendering={deferRendering}
            wrapperAttr={{ class: 'fw-app-info-popup' }}
        >
            {loading &&
                <div className={loading ? 'fw-popup-loading-overlay visible' : 'fw-popup-loading-overlay hidden'}>
                    <LoadIndicator width={'64px'} height={'64px'} visible={true} />
                </div>
            }
            <div className='app-info-public'>
                <div className='company-logo'></div>
                <div className='app-info'>
                    <div className='app-title'>MCNL - SYSTEM</div>
                    <div className='app-detail'>미래스피더 전기차충전기 통합관리시스템</div>
                </div>
            </div>
            <TabPanel
                dataSource={licenseTabData}
                itemTitleRender={itemTitleRender}
                itemComponent={LicenseComp}
                animationEnabled
                swipeEnabled
            />
        </Popup>
    )
};

export {
    PopupAler,
    PopupConf,
    PopupWarn,
    PopupAddr,
    PopupPass,
    PopupForm,
    PopupGrid,
    PopupFile,
    PopupRepo,
    PopupBoar,
    PopupCard,
    PopupMaps,
    PopupInfo,
};