// UserAccoMana - 관리자관리 - 계정 관리
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { LoadPanel } from 'devextreme-react';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { BoolCol, CommCol, DateCol, StatCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { ContentHeader } from '../../../../script/components/header/Header';
import { UserAccoInsert } from './InfoMana-insert';
import { UserAccoUpdate } from './InfoMana-update';
import { fwUtil } from '../../../../script/util';

const userChekCell = (cellInfo) => {
    const value = cellInfo.value;
    let icon = '2';
    let text = '사용자';
    switch (value) {
        case 0:
            icon = '0';
            text = '시스템관리자'
            break;
        case 1:
            icon = '1';
            text = '충전소관리자'
            break;

        default:
            break;
    }

    return (
        <div className={'fw-user-level-column'}>
            <i className={`dx-icon-fw-user-${icon}`}></i>
            <span className={`user-${icon}`}>{text}</span>
        </div>
    )
};

function convertUserChek(userChek) {
    const userChekMap = {
        "시스템관리자": 0,
        "충전소관리자": 1,
        "사용자": 2,
        "전체": 3
    };

    return userChekMap[userChek] !== undefined ? userChekMap[userChek] : 3;
}

const userLeveList = ["전체", "시스템관리자", "충전소관리자", "사용자"];

export default function UserAccoMana(props) {
    const {
        acce, cate, code,
        mainTitle, mainRole,
    } = props;

    // api
    const infoMiddleApi = 'info/regist';
    const infoSearchApi = fwUtil.apis.create(acce, cate, infoMiddleApi, 'search');
    const infoInsertApi = fwUtil.apis.create(acce, cate, infoMiddleApi, 'insert');
    const infoUpdateApi = fwUtil.apis.create(acce, cate, infoMiddleApi, 'update');
    // 시스템
    const [loading, setLoading] = useState(true);
    // 헤더
    const title = `${mainTitle}${mainRole ? " " + mainRole : ""}`;
    // 권한
    const { authCheck } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const gridSize = "single";
    const mainKey = 'id';
    const gridRef = useRef(null);
    const [gridData, setGridData] = useState([]);
    const gridDataUpdate = useCallback((e) => setGridData(e), []);
    // 검색 조건
    const [userName, setUserName] = useState("");
    const [userChek, setUserChek] = useState("전체");
    const [compCode, setCompCode] = useState("");
    const userNameChange = useCallback((e) => { setUserName(e) }, []);
    const userChekChange = useCallback((e) => { setUserChek(e) }, []);
    const compCodeChange = useCallback((e) => { setCompCode(e) }, []);
    // 등록 팝업창
    const [infoInsertPopupVisible, setInfoInsertPopupVisible] = useState(false);
    const infoInsertPopupHide = useCallback(() => { setInfoInsertPopupVisible(false); }, []);
    // 수정 팝업창
    const [infoUpdatePopupVisible, setInfoUpdatePopupVisible] = useState(false);
    const infoUpdatePopupHide = useCallback(() => { setInfoUpdatePopupVisible(false); }, []);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        authCheck(setLoading, setAuthList, code);
    }, [authCheck, setLoading, setAuthList, code]);

    // 조회
    const handleSearch = useCallback(async () => {
        const sendData = { userName: userName, comapnyCode: compCode, userChek: convertUserChek(userChek) };
        await fwUtil.apis.search(infoSearchApi, sendData, gridDataUpdate, gridRef, null);
    }, [userName, compCode, userChek, infoSearchApi, gridDataUpdate, gridRef]);

    useEffect(() => {
        handleSearch();
    }, [handleSearch]);

    // 등록/수정/삭제 버튼 클릭
    const handleActionClick = useCallback((act) => {
        const selectedRowData = fwUtil.grid.get.sData(gridRef);
        if (selectedRowData) {
            if (act === 'info-upd') {
                setInfoUpdatePopupVisible(true);
                return;
            }
        } else {
            fwUtil.aler.toast.warn('계정 선택 후 시도해주세요.');
        };

    }, [gridRef]);

    // 추가 툴바 아이템
    const toolbarItems = useMemo(() => [
        { type: 'selc', locate: 'before', caption: '사용자레벨', value: userChek, valueChange: userChekChange, search: handleSearch, items: userLeveList },
        { type: 'text', locate: 'before', caption: '사용자명', value: userName, valueChange: userNameChange, search: handleSearch },
        { type: 'text', locate: 'before', caption: '회사코드', value: compCode, valueChange: compCodeChange, search: handleSearch, disabled: userChek !== '충전소관리자' },
        { type: 'btns', locate: 'before', icon: 'search', text: '검색', onClick: handleSearch }
    ], [userChek, userChekChange, userName, userNameChange, compCode, compCodeChange, handleSearch]);

    if (loading) { return <LoadPanel visible={true} /> };

    return (
        <>
            <ContentHeader icon={'fw-user'} title={title} />
            <div id={'fw-page-content'}>
                <PagingGrid
                    title={'관리자 현황'}
                    gridSize={gridSize}
                    mainKey={mainKey}
                    gridRef={gridRef}
                    gridDataSource={gridData}
                    ins={{ onClick: () => setInfoInsertPopupVisible(true), disabled: authList.auth_inse === 0 }}
                    upd={{ onClick: () => handleActionClick('info-upd'), disabled: authList.auth_upda === 0 }}
                    exp={{ disabled: authList.auth_dnlo === 0 }}
                    toolbar={{ default: false, items: toolbarItems }}
                    selection={'single'}
                >
                    {StatCol({ dataField: "user_chek", caption: "사용자레벨", customCell: userChekCell })}
                    {CommCol({ dataField: "id", caption: "사용자ID" })}
                    {CommCol({ dataField: "user_name", caption: "사용자명" })}
                    {CommCol({ dataField: "mobile_no", caption: "전화번호" })}
                    {CommCol({ dataField: "company_code", caption: "회사코드" })}
                    {BoolCol({ dataField: "enab_yesn", caption: "사용여부" })}
                    {BoolCol({ dataField: "memb_okay", caption: "약관동의" })}
                    {BoolCol({ dataField: "mail_okay", caption: "메일인증" })}
                    {CommCol({ dataField: "mail_idno", caption: "메일" })}
                    {CommCol({ dataField: "zips_code", caption: "우편번호" })}
                    {CommCol({ dataField: "addr_knam", caption: "주소" })}
                    {DateCol({ dataField: "user_date", caption: "가입일" })}
                    {DateCol({ dataField: "dele_date", caption: "삭제요청일" })}
                    {CommCol({ dataField: "modi_name", caption: "수정자" })}
                    {DateCol({ dataField: "modi_date", caption: "수정일" })}
                </PagingGrid>
                <UserAccoInsert
                    refresh={handleSearch}
                    insertApi={infoInsertApi}
                    visible={infoInsertPopupVisible}
                    hiding={infoInsertPopupHide}
                />
                <UserAccoUpdate
                    refresh={handleSearch}
                    mainGrid={gridRef}
                    updateApi={infoUpdateApi}
                    visible={infoUpdatePopupVisible}
                    hiding={infoUpdatePopupHide}
                />
            </div>
        </>
    );
};