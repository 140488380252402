import React, { useState, useCallback, useRef, useMemo, useEffect } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupGrid, PopupWarn } from '../../../../script/components/popup/popup';
import { ScrollGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { BoolCol, CommCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { CheckBox } from 'devextreme-react';
import { fwUtil } from '../../../../script/util';

const authGridKey = ['menu_code', 'menu_gubu'];

export function UserAuthStation(props) {
    const {
        targetApi, updateApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState([]);
    const dataUpdate = useCallback((e) => setData(e), [setData]);
    // 그리드
    const authGridRef = useRef(null);
    const [dispose, setDispose] = useState(false);
    const disposeChange = useCallback((e) => setDispose(e), []);
    // 경고 팝업
    const [warnPopupVisible, setWarnPopupVisible] = useState(false);
    const warnPopupHide = useCallback(() => setWarnPopupVisible(false), []);

    // 열릴 때
    const onShowing = useCallback(() => {
        setLoading(true);
    }, [setLoading]);

    // 선택 데이터 조회
    const onShown = useCallback(async () => {
        if (!visible || !targetApi) {
            hiding();
        }
        if (visible) {
            const res = await fwUtil.apis.search(targetApi);
            dataUpdate(res);
        }
        setLoading(false);
    }, [visible, targetApi, dataUpdate, setLoading, hiding]);

    // 제출
    const onSubmit = useCallback(async () => {
        const postData = data || [];
        if (postData) {
            setLoading(true);
            await fwUtil.apis.submit(updateApi, postData);
            setLoading(false);
            warnPopupHide();
            hiding();
        } else {
            fwUtil.aler.toast.a1Erro();
        }
    }, [data, updateApi, warnPopupHide, hiding]);

    // 열 전체 선택
    const checkAllColumns = useCallback((dataField, value) => {
        const oldsData = data;
        const updatedData = oldsData.map(item => ({
            ...item,
            [dataField]: value
        }));

        dataUpdate(updatedData);
    }, [data, dataUpdate]);

    const renderBoolCellHeader = useCallback((e) => (
        <div className='fw-selctor-column-header fw-column-flex-box'>
            <span className='fw-selctor-column-header-text'>{e.column?.caption}</span>
            <div className='fw-selctor-column-header-checkbox-wrapper'>
                <CheckBox
                    className='fw-selctor-column-header-checkbox selector'
                    hint={`${e.column?.caption} 권한 전체 선택`}
                    value={true}
                    onValueChange={() => checkAllColumns(e.column.dataField, 1)}
                />
                <CheckBox
                    className='fw-selctor-column-header-checkbox deselector'
                    hint={`${e.column?.caption} 권한 전체 해제`}
                    value={false}
                    onValueChange={() => checkAllColumns(e.column.dataField, 0)}
                />
            </div>
        </div>
    ), [checkAllColumns]);

    useEffect(() => {
        if (!visible) {
            setData([]);
            disposeChange(true);
        } else {
            disposeChange(false);
        }
    }, [visible, setData, disposeChange]);

    const gridBody = useMemo(() => (
        <ScrollGrid
            gridDataSource={data}
            mainKey={authGridKey}
            mainGrid={authGridRef}
            width={isXSmall ? 320 : isSmall ? 570 : 880}
            height={600}
            edit
            dispose={dispose}
        >
            {CommCol({ dataField: "menu_name", caption: "메뉴명", allowEditing: false, allowFiltering: true })}
            {BoolCol({ dataField: "auth_acce", caption: "접근", width: 90, headerCellRender: renderBoolCellHeader, allowFiltering: false, allowSorting: false })}
            {BoolCol({ dataField: "auth_sele", caption: "조회", width: 90, headerCellRender: renderBoolCellHeader, allowFiltering: false, allowSorting: false })}
            {BoolCol({ dataField: "auth_inse", caption: "입력", width: 90, headerCellRender: renderBoolCellHeader, allowFiltering: false, allowSorting: false })}
            {BoolCol({ dataField: "auth_upda", caption: "수정", width: 90, headerCellRender: renderBoolCellHeader, allowFiltering: false, allowSorting: false })}
            {BoolCol({ dataField: "auth_dele", caption: "삭제", width: 90, headerCellRender: renderBoolCellHeader, allowFiltering: false, allowSorting: false })}
            {BoolCol({ dataField: "auth_uplo", caption: "업로드", width: 100, headerCellRender: renderBoolCellHeader, allowFiltering: false, allowSorting: false })}
            {BoolCol({ dataField: "auth_dnlo", caption: "다운로드", width: 110, headerCellRender: renderBoolCellHeader, allowFiltering: false, allowSorting: false })}
        </ScrollGrid>
    ), [data, renderBoolCellHeader, isXSmall, isSmall, dispose]);

    return (
        <>
            <PopupGrid
                title={'충전소소관리자 권한'}
                type={'설정'}
                loading={loading}
                visible={visible}
                showing={onShowing}
                shown={onShown}
                hiding={hiding}
                onSubmit={() => setWarnPopupVisible(true)}
                width={isXSmall ? 332 : isSmall ? 582 : 892}
            >
                {gridBody}
            </PopupGrid>
            <PopupWarn
                visible={warnPopupVisible}
                hiding={warnPopupHide}
                message={'충전소 관리자 권한을 일괄 설정 하시겠습니까?'}
                warning={'해당 작업에는 다소 시간이 소요될 수 있습니다. 작업 중 창을 닫지 말아주세요!'}
                confirm={onSubmit}
            />
        </>
    );
}