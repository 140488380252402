import React, { useState, useCallback } from 'react';
import { useScreenSize } from '../../../../../utils/media-query';
import { PopupForm } from '../../../../script/components/popup/popup';
import { FormRegx, FormSelc, FormText } from '../../../../script/components/form/form-items';
import { GroupItem } from 'devextreme-react/cjs/form';
import { fwUtil } from '../../../../script/util';

const defaultData = {
    station_id: "",
    station_name: "",
    id: "",
    connector_id: "",
    env_id: "",
    state: "",
    errorCode: "",
};

// 선택 항목
const statList = [
    { en: 'Available', kr: '이용가능' },
    { en: 'Preparing', kr: '준비중' },
    { en: 'Charging', kr: '충전중' },
    { en: 'SuspendedEVSE', kr: 'EVSE 정지' },
    { en: 'SuspendedEV', kr: 'EV 정지' },
    { en: 'Finishing', kr: '충전완료' },
    { en: 'Reserved', kr: '예약' },
    { en: 'Unavailable', kr: '이용불가' },
    { en: 'Faulted', kr: '오류발생' },
    { en: 'NotFound', kr: '기기없음' }
];

export function StusManaUpdate(props) {
    const {
        refresh,
        mainGrid,
        updateApi,
        visible, hiding
    } = props;

    const { isXSmall, isSmall, isMedium } = useScreenSize();
    const [loading, setLoading] = useState(false);
    // 데이터
    const [data, setData] = useState(defaultData);
    const dataUpdate = useCallback((e) => setData(e), [setData]);

    // 열릴 때
    const onShowing = useCallback(() => {
        try {
            const seleData = fwUtil.grid.get.sData(mainGrid);
            if (seleData) {
                const targData = {
                    station_id: seleData.station_id || defaultData.station_id,
                    station_name: seleData.station_name || defaultData.station_name,
                    id: seleData.id || defaultData.id,
                    connector_id: seleData.connector_id || defaultData.connector_id,
                    env_id: seleData.env_id || defaultData.env_id,
                    state: seleData.state || defaultData.state,
                    errorCode: seleData.errorCode || defaultData.errorCode,                    
                };
                dataUpdate(targData);
            } else {
                fwUtil.aler.toast.s1Erro();
                hiding();
            }
        } catch (error) {
            console.log(error)
            fwUtil.aler.toast.s1Erro();
            hiding();
        }
    }, [dataUpdate, mainGrid, hiding]);

    // 수정
    const updateField = (field) => (value) => {
        if (!data) return;

        dataUpdate({ ...data, [field]: value });
    };

    // 제출
    const onSubmit = useCallback(async () => {
        setLoading(true);
        const postData = {
            stationId: data.station_id || defaultData.station_id,
            id: data.id || defaultData.id,
            connectorId: data.connector_id || defaultData.connector_id,
            envId: data.env_id || defaultData.env_id,
            state: data.state || defaultData.state,
            errorCode: data.errorCode || defaultData.errorCode,            
        };

        await fwUtil.apis.check(updateApi, postData, refresh, hiding);
        setLoading(false);
    }, [data, updateApi, refresh, hiding]);

    return (
        <>
            <PopupForm
                title={'충전기 상태 수정'}
                type={'수정'}
                loading={loading}
                visible={visible}
                showing={onShowing}
                hiding={hiding}
                onSubmit={onSubmit}
                width={isXSmall || isSmall ? 340 : 900}
            >
                <GroupItem colCount={isXSmall || isSmall || isMedium ? 1 : 2}>
                    {FormText({ value: data.station_name, onValueChange: updateField('station_name'), label: '충전소명', required: true, disabled: true })}
                    {FormText({ value: data.id, onValueChange: updateField('id'), label: '충전기ID', required: true, disabled: true })}
                    {FormText({ value: data.connector_id, onValueChange: updateField('connector_id'), label: '커넥터ID', required: true, disabled: true })}
                    {FormRegx({ value: data.env_id, onValueChange: updateField('env_id'), label: '환경부용 커넥터ID', rule: /^[0-9]{2}$/, placeholder: '숫자 2자', required: true })}
                    {FormSelc({ value: data.state, onValueChange: updateField('state'), label: '상태', items: statList, displayExpr: 'kr', valueExpr: 'en', required: true })}
                    {FormRegx({ value: data.errorCode, onValueChange: updateField('errorCode'), label: '에러코드', rule: /^[A-Za-z0-9]{0,10}$/, placeholder: '영문, 숫자 10자' })}
                </GroupItem>
            </PopupForm>
        </>
    );
};