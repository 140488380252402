// AnalPaysStat - 정산/지표분석 - 신용카드 결제 조회
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { LoadPanel } from 'devextreme-react';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DateCol, DeciCol, NumbCol, StatCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { ContentHeader } from '../../../../script/components/header/Header';
import { fwUtil } from '../../../../script/util';

const paysTypeCell = (paysInfo) => {
    const value = paysInfo.value;
    let displayText = '-';
    let color = '#9E9E9E';

    if (value === "R") {
        displayText = '환경부 로밍';
        color = '#4CAF50';
    } else if (value === "A") {
        displayText = '관리자 카드';
        color = '#FF9800';
    } else if (value === "Y") {
        displayText = '회원카드';
        color = '#2196F3';
    } else if (value === "N") {
        displayText = '비회원';
        color = '#F44336';
    } 

    return (
        <div className={'fw-user-level-column'}>
            <span className={`fw-chrg-state-text`} style={{ color: color }}>● {displayText}</span>
        </div>
    );
};


export default function AnalPaysStat(props) {
    const {
        acce, cate, code,
        mainTitle, mainRole,
    } = props;

    // api
    const infoMiddleApi = 'pays/status';
    const infoSearchApi = fwUtil.apis.create(acce, cate, infoMiddleApi, 'search');
    // 시스템
    const [loading, setLoading] = useState(true);
    // 헤더
    const title = `${mainTitle}${mainRole ? " " + mainRole : ""}`;
    // 권한
    const { authCheck } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const gridSize = "single";
    const gridRef = useRef(null);
    const [gridData, setGridData] = useState([]);
    const gridDataUpdate = useCallback((e) => setGridData(e), []);
    // 검색 조건
    const [dateValue, setDateValue] = useState(fwUtil.date.getsText.range());
    const [duraYesn, setDuraYesn] = useState(false);    
    const [stationName, setStationName] = useState("");
    const [chargerId, setChargerId] = useState("");
    const dateValueChange = useCallback((e) => { setDateValue([e.value[0], e.value[1]]) }, []);
    const duraYesnChange = useCallback((e) => { setDuraYesn(e.value) }, []);    
    const stationNameChange = useCallback((e) => { setStationName(e) }, []);
    const chargerIdChange = useCallback((e) => { setChargerId(e) }, []);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        authCheck(setLoading, setAuthList, code);
    }, [authCheck, setLoading, setAuthList, code]);

    // 조회
    const handleSearch = useCallback(async () => {
        const sendData = { start: dateValue[0], compl: dateValue[1], duraYesn: duraYesn, stationName: stationName, chargerId: chargerId };
        await fwUtil.apis.search(infoSearchApi, sendData, gridDataUpdate, gridRef, null);
    }, [dateValue, duraYesn, stationName, chargerId, infoSearchApi, gridDataUpdate, gridRef]);

    useEffect(() => {
        handleSearch();
    }, [handleSearch]);


    // 추가 툴바 아이템
    const toolbarItems = useMemo(() => [
        { type: 'text', locate: 'before', caption: '충전소명', value: stationName, valueChange: stationNameChange, search: handleSearch },
        { type: 'text', locate: 'before', caption: '충전기 ID', value: chargerId, valueChange: chargerIdChange, search: handleSearch },
        { type: 'btns', locate: 'before', icon: 'search', text: '검색', onClick: handleSearch }
    ], [stationName, stationNameChange, chargerId, chargerIdChange, handleSearch]);

    if (loading) { return <LoadPanel visible={true} /> };

    return (
        <>
            <ContentHeader icon={'fw-user'} title={title} />
            <div id={'fw-page-content'}>
                <PagingGrid
                    title={'신용카드 결제 현황'}
                    gridSize={gridSize}
                    gridRef={gridRef}
                    gridDataSource={gridData}
                    dateValue={dateValue}
                    dateValueChange={dateValueChange}
                    duraYesn={duraYesn}
                    duraYesnChange={duraYesnChange}                    
                    exp={{ disabled: authList.auth_dnlo === 0 }}
                    toolbar={{ default: true, items: toolbarItems }}
                    selection={'single'}
                >
                    {CommCol({ dataField: "station_name", caption: "충전소명" })}
                    {CommCol({ dataField: "chargePointId", caption: "충전기 ID" })}
                    {CommCol({ dataField: "connectorId", caption: "커넥터 ID" })}
                    {CommCol({ dataField: "transactionId", caption: "트랜잭션 ID" })}
                    {CommCol({ dataField: "command", caption: "커맨드" })}
                    {CommCol({ dataField: "startTimeStamp", caption: "충전 시작 시간" })}
                    {CommCol({ dataField: "stopTimeStamp", caption: "충전 종료 시간" })}
                    {StatCol({ dataField: "payType", caption: "결제방식", customCell: paysTypeCell, width: 140 })}                    
                    {NumbCol({ dataField: "deposit", caption: "취소 금액" })}
                    {CommCol({ dataField: "payResult", caption: "결제 성공 여부" })}
                    {CommCol({ dataField: "pgTransactionNum", caption: "부가 정보" })}
                    {CommCol({ dataField: "payId", caption: "결제 ID" })}
                    {CommCol({ dataField: "approvalNum", caption: "승인 번호" })}
                    {CommCol({ dataField: "transactionDate", caption: "승인 년월" })}
                    {CommCol({ dataField: "transactionTime", caption: "승인 시간" })}
                    {CommCol({ dataField: "authAmount", caption: "승인 금액" })}
                    {CommCol({ dataField: "cardNum", caption: "승인 카드 번호" })}
                    {DateCol({ dataField: "last_upt_date", caption: "마지막 업데이트 날짜" })}
                    {DeciCol({ dataField: "resultPrice", caption: "실충전 사용 금액", suffix: "원" })}
                    {DeciCol({ dataField: "unitPrice", caption: "충전 요금 단가", suffix: "원" })}
                    {DeciCol({ dataField: "usePower", caption: "실충전량", suffix: "kWh" })}
                </PagingGrid>
            </div>
        </>
    );
};