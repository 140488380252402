// UserCredMana - 사용자 관리 - 신용카드 관리
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { LoadPanel } from 'devextreme-react';
import { useAuth } from '../../../../../contexts/auth';
import { PagingGrid } from '../../../../script/components/data-grid/data-grid-body/grid-bodys';
import { CommCol, DateCol, StatCol } from '../../../../script/components/data-grid/data-grid-column/grid-column';
import { ContentHeader } from '../../../../script/components/header/Header';
import { fwUtil } from '../../../../script/util';

const cardMainCell = (cellInfo) => {
    const value = cellInfo.value;
    const text = (!value || value === "Y") ? '주 카드' : '보조 카드';
    const color = (!value || value === "Y") ? '#4CAF50' : '#FFC107';

    return (
        <div className={'fw-user-level-column'}>
            <span className={`fw-chrg-state-text`} style={{ color: color }}>
                {text}
            </span>
        </div>
    );
};

export default function UserCredMana(props) {
    const {
        acce, cate, code,
        mainTitle, mainRole,
    } = props;

    // api
    const infoMiddleApi = 'credit/card/regist';
    const infoSearchApi = fwUtil.apis.create(acce, cate, infoMiddleApi, 'search');
    // 시스템
    const [loading, setLoading] = useState(true);
    // 헤더
    const title = `${mainTitle}${mainRole ? " " + mainRole : ""}`;
    // 권한
    const { authCheck } = useAuth();
    const [authList, setAuthList] = useState([]);
    // 그리드
    const gridSize = "single";
    const mainKey = 'id';
    const gridRef = useRef(null);
    const [gridData, setGridData] = useState([]);
    const gridDataUpdate = useCallback((e) => setGridData(e), []);
    // 검색 조건    
    const [customerName, setCustomerName] = useState("");
    const [cardAlias, setCardAlias] = useState("");
    const customerNameChange = useCallback((e) => { setCustomerName(e) }, []);
    const cardAliasChange = useCallback((e) => { setCardAlias(e) }, []);

    // 접근 권한 조회 및 권한 상태값 설정
    useEffect(() => {
        authCheck(setLoading, setAuthList, code);
    }, [authCheck, setLoading, setAuthList, code]);

    // 조회
    const handleSearch = useCallback(async () => {
        const sendData = { customerName: customerName, cardAlias: cardAlias };
        await fwUtil.apis.search(infoSearchApi, sendData, gridDataUpdate, gridRef, null);
    }, [customerName, cardAlias, infoSearchApi, gridDataUpdate, gridRef]);

    useEffect(() => {
        handleSearch();
    }, [handleSearch]);

    // 추가 툴바 아이템
    const toolbarItems = useMemo(() => [
        { type: 'text', locate: 'before', caption: '소유자명', value: customerName, valueChange: customerNameChange, search: handleSearch },
        { type: 'text', locate: 'before', caption: '카드사명', value: cardAlias, valueChange: cardAliasChange, search: handleSearch },
        { type: 'btns', locate: 'before', icon: 'search', text: '검색', onClick: handleSearch }
    ], [customerName, customerNameChange, cardAlias, cardAliasChange, handleSearch]);

    if (loading) { return <LoadPanel visible={true} /> };

    return (
        <>
            <ContentHeader icon={'fw-user'} title={title} />
            <div id={'fw-page-content'}>
                <PagingGrid
                    title={'결제카드 현황'}
                    gridSize={gridSize}
                    mainKey={mainKey}
                    gridRef={gridRef}
                    gridDataSource={gridData}
                    toolbar={{ default: false, items: toolbarItems }}
                    selection={'single'}
                >
                    {CommCol({ dataField: "customerName", caption: "고객명" })}
                    {CommCol({ dataField: "cardNo", caption: "카드번호" })}
                    {CommCol({ dataField: "validYymm", caption: "유효기간" })}
                    {CommCol({ dataField: "cardAlias", caption: "카드사명" })}
                    {StatCol({ dataField: "mainYn", caption: "주카드 여부", customCell: cardMainCell })}
                    {DateCol({ dataField: "regDt", caption: "등록일자" })}
                    {CommCol({ dataField: "regId", caption: "등록자ID" })}
                    {CommCol({ dataField: "regName", caption: "등록자명" })}
                </PagingGrid>
            </div>
        </>
    );
};