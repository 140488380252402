import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Form, LoadPanel, ScrollView } from "devextreme-react";
import { Animation, Hide, Show } from "devextreme-react/cjs/load-panel";
import { ContentHeader } from "../../../../script/components/header/Header";
import { PanelToolbar } from "../../../../script/components/detail-panel/detail-panel-toolbar/PanelToolbar";
import { PopupAddr, PopupPass, PopupWarn } from "../../../../script/components/popup/popup";
import { useScreenSize } from "../../../../../utils/media-query";
import { GroupItem } from "devextreme-react/cjs/form";
import { FormText } from "../../../../script/components/form/form-items";
import { UserCardInfoInsert } from "./CardInfoStat-insert";
import { UserCardInfoUpdate } from "./CardInfoStat-update";
import { fwUtil } from "../../../../script/util";
import UserCardInfoSection from "./CardInfoStat-section";
import UserInfoHeadSection from "./UserInfoStat-section";
import './user.scss';

const defaultData = {
    id: '',                               // 사용자ID
    pwd: '',                              // 비밀번호
    pwd_conf: '',                         // 비밀번호 확인
    pwd_curr: '',                         // 현재 비밀번호  
    user_name: '',                        // 사용자명
    mobile_no: '',                        // 연락처
    mail_idno: '',                        // 이메일
    zips_code: '',                        // 우편번호
    addr_knam: '',                        // 주소
    addr_deta: '',                        // 상세주소
    rfid_numb: '',                        // 회원카드번호
};

export default function UserInfoStat() {
    // api
    const userSearchApi = '/privat/user/sys/info/regist/search';
    const userUpdateApi = '/privat/user/sys/info/regist/update';
    const cardSearchApi = '/privat/user/sys/info/card/regist/search';
    //const cardPaymenApi = 'pg사 API';
    const cardInsertApi = '/privat/user/sys/info/card/regist/insert';
    const cardUpdateApi = '/privat/user/sys/info/card/regist/update';
    const cardDeleteApi = '/privat/user/sys/info/card/regist/delete';
    // 시스템
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const { isXSmall, isSmall, isMedium } = useScreenSize();
    const [isEditing, setIsEditing] = useState(false);
    // 데이터
    const [userData, setUserData] = useState(defaultData);
    const userDataUpdate = useCallback((e) => setUserData(e), []);
    // 결제 카드
    const [selectedCard, setSelctedCard] = useState("");
    const [paysCardList, setPaysCardList] = useState([]);
    const paysCardListUpdate = useCallback((e) => setPaysCardList(e), []);
    const [cardInsertPopupVisible, setCardInsertPopupVisibile] = useState(false);
    const cardInsertPopupHide = useCallback(() => setCardInsertPopupVisibile(false), []);
    const [starUpdatePopupVisible, setStarUpdatePopupVisibile] = useState(false);
    const starUpdatePopupHide = useCallback(() => setStarUpdatePopupVisibile(false), []);
    const starUpdatePopupShow = useCallback((e) => {
        setSelctedCard(e);
        setStarUpdatePopupVisibile(true);
    }, []);
    const [cardUpdatePopupVisible, setCardUpdatePopupVisibile] = useState(false);
    const cardUpdatePopupHide = useCallback(() => setCardUpdatePopupVisibile(false), []);
    const cardUpdatePopupShow = useCallback((e) => {
        setSelctedCard(e);
        setCardUpdatePopupVisibile(true);
    }, []);
    const [cardDeletePopupVisible, setCardDeletePopupVisibile] = useState(false);
    const cardDeletePopupHide = useCallback(() => setCardDeletePopupVisibile(false), []);
    const cardDeletePopupShow = useCallback((e) => {
        setSelctedCard(e);
        setCardDeletePopupVisibile(true);
    }, []);
    // 비밀번호 변경
    const [passwordPopupVisible, setPasswordPopupVisibile] = useState(false);
    const passwordPopupHide = useCallback(() => setPasswordPopupVisibile(false), []);
    // 주소찾기
    const [addrPopupVisible, setAddrSearchPopupVisible] = useState(false);
    const addrPopupHide = useCallback(() => setAddrSearchPopupVisible(false), []);
    const handleAddrFindButtonClick = useCallback(() => {
        setAddrSearchPopupVisible(true);
    }, []);

    const addrFindButton = useMemo(() => ({
        name: 'fw-addr-find-btns',
        loca: 'after',
        text: '주소찾기',
        mode: 'outlined',
        class: 'fw-text-btns',
        click: handleAddrFindButtonClick,
        visible: isEditing,
    }), [isEditing, handleAddrFindButtonClick]);

    const addrPopupConfirm = useCallback((postData) => {
        if (!postData || !postData.address) {
            fwUtil.aler.toast.erro('주소 정보를 가져오는 데 실패하였습니다.');
            return;
        } else {
            userDataUpdate({ ...userData, "zips_code": postData.zonecode || "", "addr_knam": postData.address || "00000" });
        }
    }, [userData, userDataUpdate]);

    // 수정 상태 전환
    const toggleEditHandler = useCallback(() => {
        setIsEditing(!isEditing);
    }, [isEditing]);

    const handleUserSearch = useCallback(async () => {
        const userRes = await fwUtil.apis.search(userSearchApi);
        if (fwUtil.conv.check.nua(userRes)) {
            navigate('/home');
            fwUtil.aler.toast.erro('잘못된 접근입니다');
            window.location.reload();
            return;
        } else {
            const updatedData = {
                id: userRes[0].id || '',
                user_name: userRes[0].user_name || '',
                mobile_no: userRes[0].mobile_no || '',
                mail_idno: userRes[0].mail_idno || '',
                zips_code: userRes[0].zips_code || '',
                addr_knam: userRes[0].addr_knam || '',
                addr_deta: userRes[0].addr_deta || '',
                rfid_numb: userRes[0].rfid_numb || '',
            };
            userDataUpdate(updatedData);
        }
    }, [navigate, userDataUpdate]);

    const handleCardSearch = useCallback(async () => {
        const cardRes = await fwUtil.apis.search(cardSearchApi);
        const cardData = cardRes;

        paysCardListUpdate(cardData);
    }, [paysCardListUpdate]);

    // 열릴 때
    const onShowing = useCallback(async () => {
        await handleUserSearch();
        await handleCardSearch();
        setLoading(false);
    }, [handleUserSearch, handleCardSearch]);

    useEffect(() => {
        onShowing();
    }, [onShowing]);

    // 사용자 정보 제출
    const onSubmit = useCallback(async () => {
        setLoading(true);
        const postData = {
            userName: userData.user_name || '',
            mobileNo: userData.mobile_no || '',
            mailIdno: userData.mail_idno || '',
            zipsCode: userData.zips_code || '',
            addrKnam: userData.addr_knam || '',
            addrDeta: userData.addr_deta || '',
        };

        await fwUtil.apis.submit(userUpdateApi, postData);
        setIsEditing(false);
        setLoading(false);
        handleUserSearch();
    }, [userData, userUpdateApi, handleUserSearch]);

    // 수정
    const updateField = (field) => (value) => {
        if (!userData) return;

        userDataUpdate({ ...userData, [field]: value });
    };

    // 주 사용카드 교체
    const starCardChange = useCallback(() => {
        if (selectedCard) {
            const postData = {
                credName: selectedCard.cred_name || '',
                credView: selectedCard.cred_view || '',
                credNumb: selectedCard.cred_numb || '0000000000000000',
                credColo: selectedCard.cred_colo || 'gray',
                credYesn: selectedCard.cred_yesn || false,
            }
            fwUtil.apis.submit(cardUpdateApi, postData);
            starUpdatePopupHide();
            handleCardSearch();
        } else {
            fwUtil.aler.toast.warn('선택된 카드가 없습니다');
        }
    }, [selectedCard, starUpdatePopupHide, handleCardSearch]);

    // 카드 정보 삭제
    const deleteCard = useCallback(() => {
        if (selectedCard) {
            const postData = {
                credName: selectedCard.cred_name || '',
                credView: selectedCard.cred_view || '',
                credNumb: selectedCard.cred_numb || '0000000000000000',
                credColo: selectedCard.cred_colo || 'gray',
                credYesn: selectedCard.cred_yesn || false,
            }
            fwUtil.apis.submit(cardDeleteApi, postData);
            cardDeletePopupHide();
            handleCardSearch();
        } else {
            fwUtil.aler.toast.warn('선택된 카드가 없습니다');
        }
    }, [selectedCard, cardDeletePopupHide, handleCardSearch]);

    return (
        <>
            <LoadPanel visible={loading} shadingColor='rgba(0,0,0,.4)'>
                <Animation>
                    <Hide type='fadeOut' delay={400} />
                    <Show type='fadeIn' delay={400} />
                </Animation>
            </LoadPanel>
            <ContentHeader
                root={'user'}
                rootLink={'/user/infostat'}
                path={['고객서비스', '내 정보']}
            />
            <div id={'fw-page-content'} className={'profile user-page'}>
                <div className={'fw-profile-container fw-column-flex-box'}>
                    <UserInfoHeadSection
                        data={userData}
                    />
                    <PanelToolbar
                        isEditing={isEditing}
                        toggleEditHandler={toggleEditHandler}
                        onSaveClick={onSubmit}
                        upd={{ disabled: false }}
                    />
                    <ScrollView>
                        <div className={'fw-profile-body'}>
                            <form>
                                <Form className={'fw-profile-form'}>
                                    <GroupItem cssClass={'fw-profile-user-info fw-profile-group'} colCount={isXSmall ? 1 : isSmall ? 2 : isMedium ? 3 : 4}>
                                        <GroupItem colCount={isXSmall ? 1 : isSmall ? 2 : isMedium ? 3 : 4} colSpan={isXSmall ? 1 : isSmall ? 2 : isMedium ? 3 : 4}>
                                            {FormText({ value: userData.user_name, onValueChange: updateField('user_name'), label: "이름", readOnly: !isEditing, hideMarker: !isEditing })}
                                            {FormText({ value: userData.mobile_no, onValueChange: updateField('mobile_no'), label: "연락처", readOnly: !isEditing, hideMarker: !isEditing, mask: '999-9999-9999' })}
                                            {FormText({ value: userData.mail_idno, onValueChange: updateField('mail_idno'), label: "메일", disabled: true, readOnly: !isEditing, hideMarker: !isEditing })}
                                        </GroupItem>
                                        <GroupItem colCount={isXSmall ? 1 : isSmall ? 2 : isMedium ? 3 : 4} colSpan={isXSmall ? 1 : isSmall ? 2 : isMedium ? 3 : 4}>
                                            {FormText({ value: userData.zips_code, onValueChange: updateField('zips_code'), label: "우편번호", maxLength: 5, readOnly: !isEditing, button: addrFindButton })}
                                        </GroupItem>
                                        <GroupItem colSpan={isXSmall ? 1 : isSmall ? 2 : 3}>
                                            {FormText({ value: userData.addr_knam, onValueChange: updateField('addr_knam'), label: "주소", maxLength: 200, readOnly: true })}
                                            {FormText({ value: userData.addr_deta, onValueChange: updateField('addr_deta'), label: "상세주소", maxLength: 200, readOnly: !isEditing })}
                                        </GroupItem>
                                    </GroupItem>
                                    {/*
                                    <GroupItem cssClass={'fw-profile-card-info fw-profile-group'} colCount={isXSmall ? 1 : isSmall ? 2 : isMedium ? 3 : 4}>
                                        {FormText({ value: userData.rfid_numb, onValueChange: updateField('rfid_numb'), label: "회원카드 번호", maxLength: 20, readOnly: true })}
                                    </GroupItem>
                                    */}
                                </Form>
                            </form>
                            {/*
                            <UserCardInfoSection
                                cardList={paysCardList}
                                insertCard={() => setCardInsertPopupVisibile(true)}
                                updateCard={cardUpdatePopupShow}
                                deleteCard={cardDeletePopupShow}
                                starClick={starUpdatePopupShow}
                            />
                            */}
                        </div>
                    </ScrollView>
                </div>
            </div>
            <PopupAddr
                visible={addrPopupVisible}
                hiding={addrPopupHide}
                confirm={addrPopupConfirm}
            />            
            <PopupPass
                data={userData}
                isXsmall={isXSmall}
                updateField={updateField}
                visible={passwordPopupVisible}
                hiding={passwordPopupHide}
            />
            <UserCardInfoInsert
                refresh={handleCardSearch}
                insertApi={cardInsertApi}
                visible={cardInsertPopupVisible}
                hiding={cardInsertPopupHide}
            />
            <UserCardInfoUpdate
                refresh={handleCardSearch}
                selectedData={selectedCard}
                updateApi={cardUpdateApi}
                visible={cardUpdatePopupVisible}
                hiding={cardUpdatePopupHide}
            />
            <PopupWarn
                visible={starUpdatePopupVisible}
                hiding={starUpdatePopupHide}
                confirm={starCardChange}
                warning={selectedCard.card_numb || ''}
                message={'해당카드를 주 사용카드로 설정하시겠습니까?'}
            />
            <PopupWarn
                visible={cardDeletePopupVisible}
                hiding={cardDeletePopupHide}
                confirm={deleteCard}
                warning={selectedCard.card_numb || ''}
                message={'해당카드 정보를 삭제하시겠습니까?'}
            />
        </>
    )
}