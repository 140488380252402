import React, { useCallback, useState } from 'react';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import Button from 'devextreme-react/button';
import { useAuth } from '../../../../contexts/auth';
import { UserPanel } from '../';
import { PopupAler, PopupInfo } from '../popup/popup';
import { useScreenSize } from '../../../../utils/media-query';
import './Header.scss';

function LayoutHeader({ menuToggleEnabled, toggleMenu, intrComp }) {
  const { signOut } = useAuth();
  const { isXSmall } = useScreenSize();
  const [appInfoPopupVisible, setAppInfoPopupVisible] = useState(false);
  const [signoutPopupVisible, setSignoutPopupVisible] = useState(false);
  const signoutHide = useCallback(() => { setSignoutPopupVisible(false); }, []);
  const appInfoHide = useCallback(() => { setAppInfoPopupVisible(false); }, []);

  return (
    <header className={'header-component'}>
      <Toolbar className={'header-toolbar'}>
        <Item
          visible={menuToggleEnabled}
          location={'before'}
          widget={'dxButton'}
          cssClass={'menu-button'}
        >
          <Button icon="menu" stylingMode="text" onClick={toggleMenu} />
        </Item>
        <Item
          location={'after'}
          locateInMenu={'auto'}
          menuItemTemplate={'userPanelTemplate'}
        >
          <UserPanel intrComp={intrComp} />
        </Item>
        <Item
          location={'after'}
          locateInMenu={'auto'}
        >
          <Button
            icon={'errorcircle'}
            stylingMode='text'
            onClick={() => setAppInfoPopupVisible(true)}
          />
        </Item>        
        <Item
          location={'after'}
          locateInMenu={'auto'}
        >
          <Button
            icon={'runner'}
            stylingMode='text'
            onClick={() => setSignoutPopupVisible(true)}
          />
        </Item>
      </Toolbar>
      <PopupAler
        visible={signoutPopupVisible}
        hiding={signoutHide}
        confirm={signOut}
        message={'로그아웃 하시겠습니까?'}
      />
      <PopupInfo 
        fullSize={isXSmall}
        visible={appInfoPopupVisible}
        hiding={appInfoHide}      
      />
    </header>
  )
}

function ContentHeader({ icon, title, refresh }) {
  return (
    <div className='fw-content-header'>
      <div className='header-before'>
        {icon && <i className={`dx-icon-${icon}`}></i>}
        <span>{title}</span>
      </div>
      <div className='header-after'>
        {refresh &&
          <Button
            icon='refresh'
            text='화면갱신'
            stylingMode='contained'
            onClick={refresh}
          />
        }
      </div>
    </div>
  )
}

export {
  LayoutHeader,
  ContentHeader
}