import React from "react";
import { Button } from "devextreme-react";
import "./pays-card.scss";
import { fwUtil } from "../../util";

const PaysCard = (props) => {
    const { type, data, onClick, edit } = props;
    const allowEdit = fwUtil.conv.tern(true, edit);

    if (type === "default") {
        return (
            <div className={'fw-pays-card-box default'} onClick={onClick}>
                <div className={'fw-pays-card-add-icon'}>+</div>
                <div className={'fw-pays-card-add-mes'}>추가하기</div>
            </div>            
        )
    }

    if (!data) {
        return;
    }

    return (
        <div className={`fw-pays-card-box real ${data.card_colo}`}>
            <div className={'fw-pays-card-header'}>
                <div  className={'fw-pays-card-name-wrapper'}>
                    <div className={'fw-pays-card-name'}>{data.card_name || '-'}</div> 
                    {allowEdit && <div className={`fw-pays-card-favo ${data.favo_yesn ? 'favo' : 'comm'}`} title="즐겨찾기">★</div>}
                </div>
                {onClick &&
                <Button
                    icon="edit"
                    stylingMode="text"
                    onClick={() => onClick(data)}
                />                    
                }
            </div>
            <div className={'fw-pays-card-detail'}>
                <div className={'fw-pays-card-date'}>{data.expi_date || '00/00'}</div>
                <div className={'fw-pays-card-numb'}>{data.card_numb || '0000-0000-0000-0000'}</div>
            </div>
        </div>
    )
};

export {
    PaysCard,
}